import { configureStore } from '@reduxjs/toolkit';
import leaguesSlice from './slices/leaguesSlice';
import filterSettingSlice from './slices/filterSettingSlice';
import refreshSlice from './slices/refreshSlice';
import marketOddsSlice from './slices/marketOddsSlice';
import betListsSlice from './slices/betListsSlice';
import sortBySlice from './slices/sortBySlice';
import dateRangeSlice from './slices/dateRangeSlice';
import refreshBalanceSlice from './slices/refreshBalanceSlice';
import userInfoSlice from './slices/userInfoSlice';
// import taxSlice from './slices/taxSlice';
import langSlice from './slices/langSlice';
import settingsSlice from './slices/settingsSlice';
import callMatchsApiSlice from './slices/callMatchsApiSlice';

export const store = configureStore({
  reducer: {
      leagues: leaguesSlice,
      sortType: sortBySlice,
      filterSetting: filterSettingSlice, 
      refresh: refreshSlice,
      marketOdds: marketOddsSlice,
      betLists: betListsSlice,
      dateRange: dateRangeSlice,
      refreshBalance: refreshBalanceSlice,
      userInfo: userInfoSlice,
      // tax: taxSlice,
      lang: langSlice,
      setting: settingsSlice,
      callMatchs: callMatchsApiSlice
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch