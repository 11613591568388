import { createSlice } from '@reduxjs/toolkit'

const initialState:any = {
    callStatus: false,
}

export const callMatchsApiSlice:any = createSlice({
  name: 'callStatus',
  initialState,
  reducers: {
    callStatusHandler: (state:any, action: any) => {
        state.callStatus = action.payload
    },
  },
})

export const { callStatusHandler } = callMatchsApiSlice.actions

export default callMatchsApiSlice.reducer