import React from 'react'
import Layout from '../../layout/Layout'
import Body from '../../components/Football/Body/Body'
import { langChange } from '../../lang'

const CorrectScoresPage = () => {
  return (
    <Layout type='football' title={langChange.correct_scores} filter="correct-scores" showHeader={false} showFooter={false} home={false} showDate={false} download={false} showWallet={false} search={false}>
       <Body />
    </Layout>
  )
}

export default CorrectScoresPage
