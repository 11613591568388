import { Button, Drawer, Space } from 'antd'
import React from 'react'
import { langChange } from '../lang'

const DrawerSlide = ({children,title,placement, onClose, open,height,showCloseBtn}:any) => {
  
  return (
    <Drawer
          title={title}
          placement={placement}
          closable={false}
          onClose={onClose}
          open={open}
          getContainer={false}
          height={height}
          extra={
            showCloseBtn &&<Space>
              <Button onClick={onClose}>Close</Button>
            </Space>
          }
          style={{
            position: 'absolute',
            right: 0,
            width: (title === langChange.correct_scores || title === 'Transaction Types')? '100%': '300px',
            textAlign: 'start',
            backgroundColor: '#333852',
            boxShadow: '-6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05)',
          }}
        >
         {children}   
    </Drawer>
  )
}

export default DrawerSlide
