import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import {Autoplay,Pagination}  from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import Marquee from "react-fast-marquee";
import "./Banner.css";
import { publicGetMethodService } from '../../services';
import { BANNER_API } from '../../services/apis';
import { useSelector } from 'react-redux';
const Banner = () => {
  const [banners,setBanners] = useState([]);
  const {settings} = useSelector((state:any) => state.setting);
  const fetchBanners = async () => {
    const res = await publicGetMethodService(BANNER_API);
    if(res){
        setBanners(res?.data)
    };
  };

  useEffect(()=>{
    fetchBanners();
  },[]);

  return (
    <div>
        <div className="banner">
          <Swiper
            spaceBetween={10}
            pagination={false}
            modules={[Pagination, Autoplay]}
            // className="mySwiper rounded-lg"
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            speed={3000}
          >
            {
              banners?.length > 0 &&
              banners?.map((banner:any) =>
                <SwiperSlide key={banner?.id}>
                    <img className={"banner-img animate-fadein"} src={banner?.url} alt="Banner" />
                </SwiperSlide>
              )
            }
          </Swiper>
        </div>
        {
          settings?.announancement &&
          <div className="captionContainer">
              <div className="banner-frame">
                  <Marquee 
                      gradient={false}
                      speed={50}
                  >
                      {/* {"ဘဝပျက်လောက်တဲ့အထိတော့ လောင်းကစားမလုပ်ဖို့ အကြံပြုပါရစေ။"} ... &nbsp; */}
                      {settings?.announancement} ... &nbsp;
                  </Marquee>
              </div>
          </div>
        }
      </div>
  )
}

export default Banner
