import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    settings: null,
};
export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setsettings: (state, action) => {
            state.settings = action.payload
        },
    },
});

export const { setsettings } = settingsSlice.actions
export default settingsSlice.reducer