import React from 'react'
import Layout from '../../layout/Layout'
import Parlay from '../../components/Football/Parlay/Parlay'
import { langChange } from '../../lang'

const ParlayPage = () => {
  return (
    <Layout type='football' title={langChange.parlay} filter="full-time" showHeader={false} showFooter={false} home={false} showDate={false} download={false} showWallet={false} search={false}>
       <Parlay />
    </Layout>
  )
}

export default ParlayPage
