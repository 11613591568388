import React, { useEffect, useState } from 'react';
import { LayoutTypes } from '../types';
import classes from './Layout.module.css';
import Footer from './Footer/Footer';
import PageTransition from './PageTransition';
import Header from './Header/Header';
import FootballHeader from './FootballHeader/FootballHeader';
import FootballFooter from './FootballFooter/FootballFooter';
import { Button, Checkbox, CheckboxProps, Divider, Drawer, GetProp, Input, Modal, Radio, Space, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { filterHandler } from '../store/slices/filterSettingSlice';
import { decodeAuth, encodeAuth, touchSong } from '../utils/Helper';
import { refreshHandler } from '../store/slices/refreshSlice';
import DrawerSlide from '../utils/DrawerSlide';
import { marketStatusHandler } from '../store/slices/marketOddsSlice';
import { useLocation } from 'react-router-dom';
import { sortByTypeHandler } from '../store/slices/sortBySlice';
import { langChange } from '../lang';

type CheckboxValueType = GetProp<typeof Checkbox.Group, 'value'>[number];
const CheckboxGroup = Checkbox.Group;
let callApiStatus = true;

const Layout = ({children, type, title,filter,showHeader,showFooter,home, showDate,download,showWallet, search}: LayoutTypes) => {
  const [openLeagueDrawer, setOpenLeagueDrawer] = useState(false);
  const [openSortDrawer, setOpenSortDrawer] = useState(false);
  const [fullTimeStatus, setFullTimeStatus] = useState(false);
  const [firstHalfStatus, setFirstHalfStatus] = useState(false);
  const [evenOddStatus, setEvenOddStatus] = useState(false);
  const [corretScoresStatus, setCorretScoresStatus] = useState(false);
  const [oneXTwoStatus, setOneXTwoStatus] = useState(false);
  const [checkedList, setCheckedList] = useState<any>([]);
  const [allMarketStatus, setAllMarketStatus] = useState<any>({});

  const {leagues} = useSelector((state:any) => state.leagues);
  const { pathname } = useLocation();
  const {sortByType} = useSelector((state:any) => state.sortType);
  const dispatch = useDispatch();

  const [messageApi, contextHolder] = message.useMessage();
  const alertMessage = (status:any, msg:any) => {
    messageApi.open({
      key: 'alert',
      type: status,
      content: msg,
    });
  };

  useEffect(()=>{
    let getSaveOddsType = localStorage.getItem("odds_type");
    let deOddsType = decodeAuth(getSaveOddsType);
    if(deOddsType){
      setFullTimeStatus(deOddsType?.fullTime);
      setFirstHalfStatus(deOddsType?.firstHalf);
      setEvenOddStatus(deOddsType?.evenOdd);
      setCorretScoresStatus(deOddsType?.corretScores);
      setOneXTwoStatus(deOddsType?.onextwo);
    };
  },[])

  useEffect(()=>{
    setCheckedList(leagues)
  },[leagues]);

  const checkAll = leagues.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < leagues.length;

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    setCheckedList(e.target.checked ? leagues : []);
  };

  useEffect(()=>{
      const store:any = {
        leagues: checkedList,
        checkAll
      };
      dispatch(filterHandler(store));
  },[checkedList]);

  const showLeagueDrawer = () => {
    setOpenLeagueDrawer(true);
    touchSong();
  };
  const onLeagueClose = () => {
    setOpenLeagueDrawer(false);
    touchSong();
  };

  const showSortDrawer = () => {
    setOpenSortDrawer(true);
    touchSong();
  };
  const onSortClose = () => {
    setOpenSortDrawer(false);
    touchSong();
    dispatch(refreshHandler(true));
    setTimeout(()=>{
      dispatch(refreshHandler(false));
    },10)
    dispatch(marketStatusHandler(allMarketStatus));
  };

  const onRefresh = () => {
     touchSong();
    //  dispatch(refreshHandler(!refresh))
     dispatch(refreshHandler(true));
  };

  const settingStatusHandler = (setState:any) => {
      touchSong();
      setState((prev:boolean) => !prev);
  };

  const showSortByModal = () => {
    if(sortByType === "time"){
      alertMessage('info',langChange.sort_by_league);
      dispatch(sortByTypeHandler("league"));
    }else{
      alertMessage('info',langChange.sort_by_time);
      dispatch(sortByTypeHandler("time"));
    };
    touchSong();
  };

  useEffect(()=>{
      if(!fullTimeStatus && !firstHalfStatus && !evenOddStatus && !oneXTwoStatus && !corretScoresStatus){
        switch(filter){
            case 'full-time':
              callApiStatus = true;
              setFullTimeStatus(true);
            break;
            case 'first-half':
              callApiStatus = true;
              setFirstHalfStatus(true);
            break;
            case 'even-odd':
              callApiStatus = true;
              setEvenOddStatus(true);
            break;
            case 'one-x-two':
              callApiStatus = true;
              setOneXTwoStatus(true);
            break;
            case 'correct-scores':
              callApiStatus = true;
              setCorretScoresStatus(true);
            break;
            default: 
              // setFullTimeStatus(true);
        };
      };
      let marketStatusObj = {
         fullTime: fullTimeStatus,
         firstHalf: firstHalfStatus,
         evenOdd: evenOddStatus,
         onextwo: oneXTwoStatus,
         corretScores: corretScoresStatus
      };
      if(pathname === '/parlay' || pathname === '/football/bet-confirm' || pathname === '/body'){
        localStorage.setItem("odds_type",encodeAuth(marketStatusObj));
      }else{
        localStorage.removeItem("odds_type")
      };
      setAllMarketStatus(marketStatusObj);
      if(callApiStatus){
        dispatch(marketStatusHandler(marketStatusObj));
        setTimeout(()=>{
          callApiStatus = false;
        },500)
      };
  },[fullTimeStatus,firstHalfStatus,evenOddStatus,oneXTwoStatus,corretScoresStatus]);


  return (
    <>
      {contextHolder}
      <section className={classes.container} style={{padding: type === 'football'?'70px 10px 95px 10px': (showFooter? '70px 10px':(showHeader ? '70px 10px 10px 10px': '10px'))}}>
          {
            type === 'football'? 
            <FootballHeader 
              // refresh={refresh} 
              showLeagueDrawer={showLeagueDrawer} 
              showSortDrawer={showSortDrawer}
              onRefresh={onRefresh} 
              showSortByModal={showSortByModal}
              title={title}/>
            :
            (
              showHeader &&
              <Header home={home} showDate={showDate} download={download} title={title} showWallet={showWallet} search={search}/>
            )
          }
          <PageTransition>
            <div className={classes.content}>
              {
                children
              }
            </div>
          </PageTransition>
          {
            type === 'football'? 
            <FootballFooter title={title}/>
            :
            (
              showFooter &&
              <Footer />
            )
            
          }

          {/* filter leagues */}
          <DrawerSlide
            title={langChange.filter_by_league}
            onClose={onLeagueClose}
            open={openLeagueDrawer}
            placement="right"
            showCloseBtn={true}
          >
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', userSelect: 'none', color: '#000000b8', marginBottom: '10px'}}>
                  <h3 style={{color: '#FFF'}}>{leagues?.length ?? '-'} Leagues</h3>
                  <Checkbox style={{fontSize: '12px',fontWeight: 'bolder', color: '#FFF'}} indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                    {checkAll? 'Unselect': 'Select'} all
                  </Checkbox>
              </div>
              <Divider style={{borderColor: '#ffffff29'}}/>
              <CheckboxGroup style={{display: 'flex', flexDirection: 'column', userSelect: 'none'}} options={leagues} value={checkedList} onChange={onChange} />
          </DrawerSlide>

          {/* Filter Odds */}
          <DrawerSlide
            title="Odds Market Filter"
            onClose={onSortClose}
            open={openSortDrawer}
            placement="right"
            showCloseBtn={true}
          >
              <Checkbox style={{width: '100%', userSelect: 'none',padding: '13px 0'}} checked={fullTimeStatus} onChange={()=> settingStatusHandler(setFullTimeStatus)}>
                  {langChange.full_time}
              </Checkbox>
              <Divider style={{margin: '0', borderColor: '#ffffff29'}} />
              <Checkbox style={{width: '100%', userSelect: 'none',padding: '13px 0'}} checked={firstHalfStatus} onChange={()=> settingStatusHandler(setFirstHalfStatus)}>
                {langChange.first_half}
              </Checkbox>
              <Divider style={{margin: '0', borderColor: '#ffffff29'}} />
              {
                title === langChange.body &&
                <>
                  <Checkbox style={{width: '100%', userSelect: 'none',padding: '13px 0'}} checked={evenOddStatus} onChange={()=> settingStatusHandler(setEvenOddStatus)}>
                      {langChange.even_odd}
                  </Checkbox>
                  <Divider style={{margin: '0', borderColor: '#ffffff29'}} />
                  <Checkbox style={{width: '100%', userSelect: 'none',padding: '13px 0'}} checked={oneXTwoStatus} onChange={()=> settingStatusHandler(setOneXTwoStatus)}>
                      1 X 2
                  </Checkbox>
                  <Divider style={{margin: '0', borderColor: '#ffffff29'}} />
                  {/* <Checkbox style={{width: '100%', userSelect: 'none',padding: '13px 0'}} checked={corretScoresStatus} onChange={()=> settingStatusHandler(setCorretScoresStatus)}>
                    {langChange.correct_scores}
                  </Checkbox>
                  <Divider style={{margin: '0', borderColor: '#ffffff29'}} /> */}
                </>
              }
          </DrawerSlide>

      </section>
    </>
  )
}

export default Layout
