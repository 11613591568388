import React from 'react';
import classes from './Type.module.css'
import { FootballTypeTypes } from '../../../types';
import { useNavigate } from 'react-router-dom';
import { touchSong } from '../../../utils/Helper';
import { message } from 'antd';
import { useSelector } from 'react-redux';

const Type = ({img,name,href,size, btnName}: FootballTypeTypes) => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const {value} = useSelector((state:any) => state.lang);

  const alertMessage = (status:any, msg:any) => {
    messageApi.open({
    // key: 'coming_soon',
    type: status,
    content: msg,
    });
  };

  const navigateHandler = (url:string) => {
    if(url === "coming_soon"){
        return alertMessage('warning',"Coming Soon!");
    }else{
        touchSong();
        navigate(url);
    };
  };

  return (
    <>
      {contextHolder}
      <div className={classes.typeCard}>
          <div className={classes.type}>
              <img src={img} style={{width: size}} alt='type' className={classes.typeImg} />
              <span style={{padding: name === '1 X 2'? (value === 'eng'? '0':'6px 0'): '0'}} className={classes.typeName}>{name || '-'}</span>
              <button style={{padding: value === 'eng'? '8px 26px':'2px 10px', marginTop: value === 'eng'?'10px': '2px'}} className={classes.playBtn} onClick={()=> {navigateHandler(href);}}>{btnName}</button>
          </div>
      </div>
    </>
  )
}

export default Type
