import React from 'react'
import Layout from '../../layout/Layout'
import WalletTransaction from '../../components/Wallet/WalletTransaction';
import { langChange } from '../../lang';

const WalletTransactionPage = () => {
  return (
    <Layout type='' title={langChange.transaction} filter="" showHeader={true} showFooter={false} home={false} showDate={false} download={false} showWallet={false} search={false}>
        <WalletTransaction />
    </Layout>
  )
}

export default WalletTransactionPage
