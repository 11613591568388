import React from 'react'
import Layout from '../../layout/Layout'
import Profile from '../../components/Profile/Profile'

const ProfilePage = () => {
  return (
    <Layout showHeader={true} type="" title='' filter="" showFooter={true} home={true} showDate={false} download={false} showWallet={false} search={false}>
        <Profile />
    </Layout>
  )
}

export default ProfilePage
