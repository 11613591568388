import React, { useEffect, useState } from 'react';
import classes from './ChangePassword.module.css';
import PageTransition from '../../layout/PageTransition';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { postMethodService } from '../../services';
import { CHANGE_PASSWORD_API } from '../../services/apis';
import { LogoutHandle, decodeAuth, encodeAuth } from '../../utils/Helper';
import { useNavigate } from 'react-router-dom';
const loginImage = require('../../assets/images/auth/login.jpeg');
const Cookies = require('js-cookie');

const ChangePassword = () => {
    const [isLoading,setIsLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    const alertMessage = (status:any, msg:any) => {
        messageApi.open({
          key: 'changePass',
          type: status,
          content: msg,
        });
    };
    
    const onFinishHandler = async (values:any) => {
        setIsLoading(true);
        let data = {
            old_password: values?.old_password,
            password: values?.new_password,
            confirm_password: values?.confirm_password,
        };
        let res:any = await postMethodService(CHANGE_PASSWORD_API,data);
        if(res){
            setIsLoading(false);
            if(res === "Unauthenticated"){
                return LogoutHandle(navigate);
            };
            if(res.status === "success" || res.status || res.success){
                return LogoutHandle(navigate); 
            }else{
                return alertMessage('error',res);
            };
        };
    };

    const backKeyHandler = () => {
        const getAuth = Cookies.get('=d;£6792)Dr!');
        if(getAuth){
            let decode = decodeAuth(getAuth);
            if(decode?.is_password_changed){
                return navigate(-1);
            }else{
                return LogoutHandle(navigate);
            };
        };
    };
  return (
    <>
        {contextHolder}
        <section className={classes.container}>
            <PageTransition>
                <div className={classes.content}>
                    <img src={loginImage} className={classes.authImg}/>
                    <h3 className={classes.title}>Change Password</h3>
                    <div style={{width: '100%', padding: '20px'}}>
                        <Form
                            initialValues={{ remember: true }}
                            onFinish={onFinishHandler}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <Form.Item
                                label={<span className={classes.label}>Old Password</span>}
                                name="old_password"
                                rules={[{ required: true, message: 'Please input your old password!' }]}
                                style={{marginBottom: '15px'}}
                            >
                                <Input.Password className={`custom-password-input`} placeholder='စကားဝှက် အဟောင်းထည့်ပါ' />
                            </Form.Item>

                            <Form.Item
                                label={<span className={classes.label}>New Password ( <span style={{color: 'tomato'}}>A - Z နှင့် Num ပါရမည်</span> )</span>}
                                name="new_password"
                                rules={[{ required: true, message: 'Please input your new password!' }]}
                                style={{marginBottom: '15px'}}
                            >
                               <Input.Password className={`custom-password-input`} placeholder='အနည်းဆုံး ၈ လုံးဖြစ်ရမည်' />
                            </Form.Item>

                            <Form.Item
                                label={<span className={classes.label}>Confirm Password ( <span style={{color: 'tomato'}}>A - Z နှင့် Num ပါရမည်</span> )</span>}
                                name="confirm_password"
                                rules={[{ required: true, message: 'Please input your confirm password!' }]}
                                style={{marginBottom: '30px'}}
                            >
                                <Input.Password className={`custom-password-input`} placeholder='အနည်းဆုံး ၈ လုံးဖြစ်ရမည်' />
                            </Form.Item>

                            <Form.Item>
                                <div style={{
                                    display: 'flex'
                                }}>
                                    <Button onClick={backKeyHandler} type="primary" style={{width: '100%', height: 45, backgroundColor: 'gray',boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px", borderRadius: '12px', marginRight: '10px'}}>
                                        Back
                                    </Button>
                                    <Button loading={isLoading} type="primary" htmlType="submit" style={{width: '100%', height: 45, backgroundColor: 'var(--accent-color)', borderRadius: '12px', marginLeft: '10px',boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"}}>
                                        Confirm
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </PageTransition>
        </section>
    </>
  )
}

export default ChangePassword
