import React from 'react';
import MatchsType from './MatchsType';

const LiveGoals = () => {
  return (
    <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column'}}>
        <MatchsType />
    </div>
  )
}

export default LiveGoals
