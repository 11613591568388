import React from 'react'
import Layout from '../../layout/Layout'
import BetSubmit from '../../components/Football/BetSubmit/BetSubmit'

const BetSubmitPage = () => {
  return (
    <Layout type="" title='' filter="" showHeader={true} showFooter={false} home={false} showDate={false} download={false} showWallet={true} search={false}>
      <BetSubmit />
    </Layout>
  )
}

export default BetSubmitPage
