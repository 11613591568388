import React, { useEffect, useState } from 'react';
import classes from './SignIn.module.css';
import PageTransition from '../../layout/PageTransition';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { postMethodService } from '../../services';
import { LOGIN_API } from '../../services/apis';
import { LogoutHandle, decodeAuth, encodeAuth, goFullScreen } from '../../utils/Helper';
import { useNavigate } from 'react-router-dom';
const loginImage = require('../../assets/images/auth/login.jpeg');
const Cookies = require('js-cookie');

const SignIn = () => {
    const [isLoading,setIsLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    let getAcc:any = localStorage.getItem("mJ1)50D`!ET%");
    getAcc = getAcc && decodeAuth(getAcc);
    const defaultValue = {
        usercode: getAcc?.usercode? getAcc?.usercode: '',
        password:  getAcc?.password? getAcc?.password: ''
    };

    const alertMessage = (status:any, msg:any) => {
        messageApi.open({
          key: 'singIn',
          type: status,
          content: msg,
        });
    };

    // let getAcc:any = localStorage.getItem("mJ1)50D`!ET%");
        
    //     if(getAcc){
    //       getAcc = decodeAuth(getAcc);
    //       form.setFieldsValue({
    //         usercode: getAcc?.usercode,
    //         password: getAcc?.password
    //       });
    //     };
    const onFinishHandler = async (values:any) => {
        setIsLoading(true);
        let data = {
            usercode: values?.usercode,
            password: values?.password,
        };
        let res:any = await postMethodService(LOGIN_API,data);
        if(res){
            setIsLoading(false);
            if(res === "Unauthenticated"){
                return LogoutHandle(navigate);
            };
            if(res.status === "success" || res.status || res.success){
                let saveInfo = {
                    user_id: res?.data?.user?.id,
                    is_password_changed: res?.data?.user?.is_password_changed,
                    token: res?.data?.token,
                };
                const encoded = encodeAuth(saveInfo);
                Cookies.set("=d;£6792)Dr!", encoded);
                alertMessage('success','အကောင့်ဝင်ခြင်း အောင်မြင်ပါသည်။');
                setTimeout(()=>{
                    if(saveInfo?.is_password_changed){
                        if(values?.remember){
                            localStorage.setItem("mJ1)50D`!ET%", encodeAuth(data));
                        }else{
                            localStorage.removeItem("mJ1)50D`!ET%");
                        };
                        navigate('/rule-regulations');
                        // goFullScreen();
                    }else{
                        navigate('/change-password');
                    };
                },800);
            }else{
                return alertMessage('error',res);
            };
        };
    };
  return (
    <>
        {contextHolder}
        <section className={classes.container}>
            <PageTransition>
                <div className={classes.content}>
                    <img src={loginImage} className={classes.authImg}/>
                    <h3 className={classes.title}>Sign In</h3>
                    <p className={classes.welcomeText}>Welcome to 3XScores!</p>
                    <div style={{width: '100%', padding: '20px'}}>
                        <Form
                            initialValues={{ remember: true , ...defaultValue}}
                            onFinish={onFinishHandler}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <Form.Item
                                label={<span className={classes.label}>Usercode</span>}
                                name="usercode"
                                rules={[{ required: true, message: 'Please input your usercode!' }]}
                                style={{marginBottom: '15px'}}
                            >
                                <Input className={classes.input} placeholder='Usercode' />
                            </Form.Item>

                            <Form.Item
                                label={<span className={classes.label}>Password</span>}
                                name="password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                                style={{marginBottom: '15px'}}
                            >
                                <Input.Password className={`custom-password-input`} placeholder='Password length is must be 8' />
                            </Form.Item>

                            <Form.Item
                                name="remember"
                                valuePropName="checked"
                                style={{marginBottom: '10px'}}
                            >
                                <Checkbox style={{color: '#FFF'}}>Remember me</Checkbox>
                            </Form.Item>

                            <Form.Item>
                                <Button loading={isLoading} type="primary" htmlType="submit" style={{width: '100%', height: 45, backgroundColor: 'var(--accent-color)', boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",borderRadius: '12px'}}>
                                    Sign In
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </PageTransition>
        </section>
    </>
  )
}

export default SignIn
