import LocalizedStrings from 'react-localization';

export const langChange = new LocalizedStrings({
    unicode: {
        change_lang: 'ဘာသာစကား ပြောင်းမည်',
        language: 'ဘာသာစကား',
        setting: 'ပြင်ဆင်ရန်',
        change_password: 'စကားဝှက်ပြောင်းရန်',
        wallet_transaction: 'ငွေစာရင်းမှတ်တမ်း',
        rule_regulations: 'စည်းကမ်း နှင့် သတ်မှတ်ချက်များ',
        signout: 'ထွက်ရန်',
        home: 'မူလ',
        app_guild: 'App သုံးနည်း',
        notification: 'အသိပေးချက်',
        profile: 'ကျွန်ုပ်',
        play: 'ကစားမည်',
        enter: 'ကြည့်ရန်',
        body: 'ဘော်ဒီ',
        parlay: 'မောင်း',
        first_half: 'ပထမပိုင်း',
        full_time: 'ပွဲပြီး',
        rejected: 'ပွဲပျက်',
        rejected_first_half: 'ပွဲပျက် ( ပပိုင်း )',
        rejected_full_time: 'ပွဲပျက် ( ပွဲပြီး )',
        live: 'တိုက်ရိုက်',
        favourites: 'အကြိုက်ဆုံး',
        even_odd: 'စုံ / မ',
        correct_scores: 'ဂိုးခန့်မှန်း',
        transaction: 'ငွေစာရင်း',
        slips: 'စလစ်များ',
        liveGoals: 'ဂိုးရလဒ်များ',
        news: 'သတင်း',
        tips: 'ဘောလုံး Tips',
        announcement: 'ကြော်ညာချက်',
        all: 'အားလုံး',
        inflow: 'အဝင်',
        outflow: 'အထွက်',
        content: 'အကြောင်းအရာ',
        deposit: 'ငွေသွင်း',
        withdraw: 'ငွေထုတ်',
        bet: 'လောင်းငွေ',
        reward: 'နိုင်ငွေ',
        confirm: 'အတည်ပြု',
        cancel: 'ပယ်ဖျက်',
        slipId: 'စလစ် နံပါတ်',
        transaction_id: 'လုပ်ဆောင်မှုအမှတ်',
        teams: 'ပွဲစဉ်',
        bet_type: 'အမျိုးအစား',
        bet_amount: 'လောင်းငွေ',
        amount: 'ပမာဏ',
        before: 'မတိုင်မီ',
        after: 'ပြီးနောက်',
        payout: 'ပြန်ရငွေ',
        profit: 'အမြတ်',
        win_lose: 'နိုင် / ရှုံး',
        status: 'အခြေအနေ',
        result: 'ရလဒ်',
        stake: 'လောင်းကြေး',
        hour: 'အချိန်',
        football_teams: 'အသင်းများ',
        notfound: 'မှတ်တမ်းမရှိပါ',
        football_home: 'အိမ်ကွင်း',
        football_away: 'အဝေးကွင်း',
        football_over: 'ဂိုးပေါ်',
        football_under: 'ဂိုးအောက်',
        football_odd: 'မ',
        football_even: 'စုံ',
        balance: 'လက်ကျန်ငွေ',
        bet_confirm: 'လောင်းမည်',
        estimate: 'ခန့်မှန်းရငွေ',
        canwrite: 'တစ်ခုခု ရေးနိုင်ပါသည်',
        sort_by_league: 'လိဂ် ဖြင့်စီသည်',
        sort_by_time: 'အချိန် ဖြင့်စီသည်',
        filter_by_league: 'လိဂ်များအလိုက် စီသည်',
        ok: 'အိုကေ',
        payment_success: 'လုပ်ဆောင်မှုအောင်မြင်ပါသည်',
    },
    zawgyi: {
        change_lang: 'ဘာသာစကား ေျပာင္းမည္',
        language: 'ဘာသာစကား',
        setting: 'ျပင္ဆင္ရန္',
        change_password: 'စကားဝွက္ေျပာင္းရန္',
        wallet_transaction: 'ေငြစာရင္းမွတ္တမ္း',
        rule_regulations: 'စည္းကမ္း ႏွင့္ သတ္မွတ္ခ်က္မ်ား',
        signout: 'ထြက္ရန္',
        home: 'မူလ',
        app_guild: 'App သုံးနည္း',
        notification: 'အသိေပးခ်က္',
        profile: 'ကြၽႏ္ုပ္',
        play: 'ကစားမည္',
        enter: 'ၾကည့္ရန္',
        body: 'ေဘာ္ဒီ',
        parlay: 'ေမာင္း',
        first_half: 'ပထမပိုင္း',
        full_time: 'ပြဲၿပီး',
        rejected: 'ပြဲပ်က္',
        rejected_first_half: 'ပြဲပ်က္ ( ပပိုင္း )',
        rejected_full_time: 'ပြဲပ်က္ ( ပြဲၿပီး )',
        live: 'တိုက္႐ိုက္',
        favourites: 'အႀကိဳက္ဆုံး',
        even_odd: 'စုံ / မ',
        correct_scores: 'ဂိုးခန္႔မွန္း',
        transaction: 'ေငြစာရင္း',
        slips: 'စလစ္မ်ား',
        liveGoals: 'ဂိုးရလဒ္မ်ား',
        news: 'သတင္း',
        tips: 'ေဘာလုံး Tips',
        announcement: 'ေၾကာ္ညာခ်က္',
        all: 'အားလုံး',
        inflow: 'အဝင္',
        outflow: 'အထြက္',
        content: 'အေၾကာင္းအရာ',
        deposit: 'ေငြသြင္း',
        withdraw: 'ေငြထုတ္',
        bet: 'ေလာင္းေငြ',
        reward: 'ႏိုင္ေငြ',
        confirm: 'အတည္ျပဳ',
        cancel: 'ပယ္ဖ်က္',
        slipId: 'စလစ္ နံပါတ္',
        transaction_id: 'လုပ္ေဆာင္မႈအမွတ္',
        teams: 'ပြဲစဥ္',
        bet_type: 'အမ်ိဳးအစား',
        bet_amount: 'ေလာင္းေငြ',
        amount: 'ပမာဏ',
        before: 'မတိုင္မီ',
        after: 'ၿပီးေနာက္',
        payout: 'ျပန္ရေငြ',
        profit: 'အျမတ္',
        win_lose: 'ႏိုင္ / ရႈံး',
        status: 'အေျခအေန',
        result: 'ရလဒ္',
        stake: 'ေလာင္းေၾကး',
        hour: 'အခ်ိန္',
        football_teams: 'အသင္းမ်ား',
        notfound: 'မွတ္တမ္းမရွိပါ',
        football_home: 'အိမ္ကြင္း',
        football_away: 'အေဝးကြင္း',
        football_over: 'ဂိုးေပၚ',
        football_under: 'ဂိုးေအာက္',
        football_odd: 'မ',
        football_even: 'စုံ',
        balance: 'လက္က်န္ေငြ',
        bet_confirm: 'ေလာင္းမည္',
        estimate: 'ခန္႔မွန္းရေငြ',
        canwrite: 'တစ္ခုခု ေရးႏိုင္ပါသည္',
        sort_by_league: 'လိဂ္ ျဖင့္စီသည္',
        sort_by_time: 'အခ်ိန္ ျဖင့္စီသည္',
        filter_by_league: 'လိဂ္မ်ားအလိုက္ စီသည္',
        ok: 'အိုေက',
        payment_success: 'လုပ္ေဆာင္မႈေအာင္ျမင္ပါသည္',
        
    },
    eng: {
        change_lang: 'Change Language',
        language: 'Language',
        setting: 'Setting',
        change_password: 'Change Password',
        wallet_transaction: 'Wallet Transaction',
        rule_regulations: 'Rule and Regulations',
        signout: 'Sign Out',
        home: 'Home',
        app_guild: 'App Guild',
        notification: 'Notification',
        profile: 'Profile',
        play: 'Play',
        enter: 'View',
        body: 'HDP / OU',
        parlay: 'Parlay',
        first_half: `1st Half`,
        full_time: 'Full Time',
        rejected: 'Rejected',
        rejected_first_half: 'Rejected ( FH )',
        rejected_full_time: 'Rejected ( FT )',
        live: 'Live',
        favourites: 'Favourites',
        even_odd: 'Even / Odd',
        correct_scores: 'Correct Scores',
        transaction: 'Transaction',
        slips: 'Bet Slips',
        transaction_id: 'Transaction ID',
        liveGoals: 'Live Goals',
        news: 'News',
        tips: 'Football Tips',
        announcement: 'Announcement',
        all: 'All',
        inflow: 'Inflow',
        outflow: 'Outflow',
        content: 'Content',
        deposit: 'Deposit',
        withdraw: 'Withdraw',
        bet: 'Bet',
        reward: 'Reward',
        confirm: 'Confirm',
        cancel: 'Cancel',
        slipId: 'Bet Slip ID',
        teams: 'Teams',
        bet_type: 'Bet Type',
        bet_amount: 'Amount',
        amount: 'Amount',
        before: 'Before',
        after: 'After',
        payout: 'Payout',
        profit: 'Profit',
        win_lose: 'Win / Lose',
        status: 'Status',
        result: 'Result',
        stake: 'Stake',
        hour: 'Hour',
        football_teams: 'Football Teams',
        notfound: 'Not Found',
        football_home: 'Home',
        football_away: 'Away',
        football_over: 'Over',
        football_under: 'Under',
        football_odd: 'Odd',
        football_even: 'Even',
        balance: 'Balance',
        bet_confirm: 'Bet Submit',
        estimate: 'Estimate',
        canwrite: 'You can write',
        sort_by_league: 'Sort By League',
        sort_by_time: 'Sort By Time',
        filter_by_league: 'Filter By Leagues',
        ok: 'OK',
        payment_success: 'Payment Successful',
    }
});