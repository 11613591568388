import React, { useEffect } from 'react';
import classes from './Maintenance.module.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const fieldImg = require('../../assets/images/other/field.jpg');
const maintenanceImg = require('../../assets/images/other/under-main.png');

const Maintenance = () => {
  const navigate = useNavigate();
  const {settings} = useSelector((state:any) => state.setting);

  useEffect(()=>{
    if(settings?.status){
        return navigate('/');
    };
  },[settings]);

  return (
    <div>
        <div className={classes.imgContainer}>
            <div className={classes.maintenanceImgContainer}>
                <img src={maintenanceImg} />
            </div>
        </div>
        {
            settings?.remark && 
            <div className={classes.maintainCard}>
                <p className={classes.text}>
                    {settings?.remark}
                </p>
            </div>
        }
        {/* <div className={classes.btnCenter}>
            <button className={classes.backHomeBtn} onClick={() => navigate('/')}>Back to Home</button>
        </div> */}
    </div>
  )
}

export default Maintenance
