import { useQuery } from "@apollo/client";
import { GET_BET_LISTS_DATA,GET_TAX_PERCENT_DATA } from "./graphql/queries";
import { FetchGraphQL } from "./graphql/queryService";

export const FetchBetHistory = ({payload,pollInterval=0}) => {
  let result = FetchGraphQL({
      query: GET_BET_LISTS_DATA,
      variables: payload,
      pollInterval: pollInterval
  })
  return result;
};

export const FetchTaxPercent = ({payload,pollInterval=0}) => {
  let result = FetchGraphQL({
      query: GET_TAX_PERCENT_DATA,
      variables: payload,
      pollInterval: pollInterval
  })
  return result;
};
