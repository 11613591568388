import React from 'react'
import Layout from '../../layout/Layout'
import { langChange } from '../../lang'
import WalletTransactionDetail from '../../components/Wallet/WalletTransactionDetail'

const WalletTransactionDetailPage = () => {
  return (
    <Layout type='' title={langChange.transaction} filter="" showHeader={true} showFooter={false} home={false} showDate={false} download={false} showWallet={false} search={false}>
        <WalletTransactionDetail />
    </Layout>
  )
}

export default WalletTransactionDetailPage
