import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    betLists: null,
};
export const betListsSlice = createSlice({
    name: 'betLists',
    initialState,
    reducers: {
        setBetLists: (state, action) => {
            state.betLists = action.payload
        },
    },
});

export const { setBetLists } = betListsSlice.actions
export default betListsSlice.reducer