import React from 'react'
import RuleAndRegulations from '../../components/RuleAndRegulations/RuleAndRegulations'
import Layout from '../../layout/Layout'

const RuleAndRegulationsPage = () => {
  return (
    <Layout showHeader={false} type="" title='' filter="" showFooter={false} home={false} showDate={false} download={false} showWallet={false} search={false}>
        <RuleAndRegulations />
    </Layout>
  )
}

export default RuleAndRegulationsPage
