import React from 'react';
import classes from './FootballTypes.module.css';
import Type from './Type';
import { langChange } from '../../../lang';
import { useSelector } from 'react-redux';
const fieldImg = require('../../../assets/images/icons/field.png');
const footballImg = require('../../../assets/images/icons/football.png');
const walletTransImg = require('../../../assets/images/icons/transaction-history.png');
const historyImg = require('../../../assets/images/icons/history.png');
const coinsImg = require('../../../assets/images/icons/coins.png');
const replayImg = require('../../../assets/images/icons/replay.png');
const newsImg = require('../../../assets/images/icons/newspaper.png');
const annoImg = require('../../../assets/images/icons/loudspeaker.png');
const guideImg = require('../../../assets/images/icons/guidebook.png');
const twodImg = require('../../../assets/images/icons/2d.png');
const evenOddImg = require('../../../assets/images/icons/evenodd.png');
const onextwoImg = require('../../../assets/images/icons/pools.png');
const goalImg = require('../../../assets/images/icons/goal.png');
const corretScoresImg = require('../../../assets/images/icons/scoreboard.png');


const FootballTypes = () => {
  const {value} = useSelector((state:any) => state.lang);
  return (
    <div className={classes.typeContainer}>
        <Type img={footballImg} name={langChange.body} href="/body" size="23px" btnName={langChange.play} />
        <Type img={fieldImg} name={langChange.parlay} href="/parlay" size="23px" btnName={langChange.play}/>
        <Type img={footballImg} name={langChange.first_half} href="/first-half" size="23px" btnName={langChange.play}/>
        <Type img={evenOddImg} name={langChange.even_odd} href="/even-odd" size="23px" btnName={langChange.play}/>
        <Type img={onextwoImg} name="1 X 2" href="/one-x-two" size='23px' btnName={langChange.play}/>
        <Type img={corretScoresImg} name={langChange.correct_scores} href="coming_soon" size="23px" btnName={langChange.play}/>
        {/* <Type img={corretScoresImg} name={langChange.correct_scores} href="/correct-scores" size="23px" btnName={langChange.play}/> */}
        {/* <Type img={twodImg} name="နှစ်လုံးထီ" href="coming_soon" size="23px" btnName={langChange.play}/> */}
        <Type img={walletTransImg} name={langChange.transaction} href="/wallet-transaction" size="23px" btnName={langChange.enter}/>
        <Type img={historyImg} name={langChange.slips} href="/bet-slips" size="23px" btnName={langChange.enter} />
        <Type img={goalImg} name={langChange.liveGoals} href="/live-goals" size="23px" btnName={langChange.enter}/>
        {/* <Type img={coinsImg} name="ငွေစာရင်း" href="coming_soon" size="23px" btnName={langChange.enter}/> */}
        <Type img={newsImg} name={langChange.news} href="coming_soon" size="23px" btnName={langChange.enter}/>
        {/* <Type img={guideImg} name="လမ်းညွှန်" href="coming_soon" size="23px" btnName={langChange.enter}/> */}
        <Type img={guideImg} name={langChange.tips} href="coming_soon" size="23px" btnName={langChange.enter}/>
        <Type img={annoImg} name={langChange.announcement} href="coming_soon" size="23px" btnName={langChange.enter}/>
    </div>
  )
}

export default FootballTypes
