import React, { useEffect, useRef, useState } from "react";
import classes from "./BetHistory.module.css";
import { BiSolidCheckCircle, BiSolidMinusCircle } from "react-icons/bi";
import { IoIosFootball } from "react-icons/io";
import { GET_BET_LISTS_DATA_SERVICE } from "../../../services/graphql/queryService";
import { GET_BET_LISTS_DATA } from "../../../services/graphql/queries";
import { createPortal } from "react-dom";
import Loader from "../../../utils/Loader/Loader";
import NotFound from "../../../utils/NotFound";
import {
  LogoutHandle,
  capitalizedText,
} from "../../../utils/Helper";
import { useNavigate } from "react-router-dom";
import { FetchBetHistory } from "../../../services/bettingService";
import moment from "moment";
import { useSelector } from "react-redux";
import { langChange } from "../../../lang";

const BetHistory = () => {
  const [page, setPage] = useState(1);
  const [pagiHistorys,setPagiHistorys] = useState<any>([]);
  const {start_date,end_date} = useSelector((state:any) => state.dateRange.date_range);
  const {value} = useSelector((state:any) => state.lang);
  let payload = {
    page,
    pageSize: 30,
    startDate: start_date,
    endDate: end_date,
  };
  const { loading, error, data, refetch, startPolling, stopPolling }: any =
    FetchBetHistory({ payload: payload, pollInterval: 1000 * 60 });
  const navigate = useNavigate();
  const scrollRef:any = useRef();

  useEffect(() => {
    if (error?.message === "Not authenticated" || error?.message.includes("Invalid token") || error?.message.includes("Unauthorized token") || error?.message.includes("Not Authorised!")) {
      return LogoutHandle(navigate);
    }
  }, [error]);

  const bettingDetailHandler = (id: number) => {
    navigate(`/bet-slips/${id}`);
  };

  
  useEffect(()=>{
    setPagiHistorys([]);
    if(page > 1){
        setPage(1);
        scrollRef.current.scrollTop = 0;
    };
 },[start_date,end_date]);

  const scrollScreenHandler = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if ((scrollTop + clientHeight + 5) > scrollHeight) {
        let currentPage = data?.bettings?.meta?.page;
        let totalPages = Number(Math.ceil((data?.bettings?.meta?.total) / (data?.bettings?.meta?.pageSize)));
        if(totalPages){
          if(currentPage !== totalPages){
            if(pagiHistorys.length > 0){
                setPage(currentPage + 1);
            };
        };
        }
      }
    }
  };

useEffect(() => {
  let historys = data?.bettings?.data;
  if(historys){
    if ((historys?.length > 0) && (typeof historys !== "string")) {
        const uniqueVouchers = historys?.filter((item:any) => !pagiHistorys?.some((existingItem:any) => existingItem?.id == item?.id));
        setPagiHistorys((prevVouchers:any) => [...prevVouchers, ...uniqueVouchers]);
    }else{
        setPagiHistorys([]);
    };
  };
}, [data]);

const walletDetailHandler = (id:any) => {
  if(id){
    return navigate(`/wallet-transaction/detail/${id}`)
  };
}


  return (
    <>
      <div 
        className={classes.historyContainer}
        ref={scrollRef}
        onScroll={scrollScreenHandler}
      >
        {error && <NotFound text="System Error!" />}
        {pagiHistorys?.length > 0
          ? pagiHistorys?.map((betting: any) => (
              <div
                key={betting?.id}
                className={classes.passenger}
                style={{ cursor: "pointer" }}
                onClick={() => bettingDetailHandler(betting?.id)}
              >
                <div className={classes["passenger-ticket"]}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "13px",
                    }}
                  >
                    <IoIosFootball size={20} style={{ marginRight: "7px" }} />
                    <div style={{ width: "100%" }}>
                      {/* <p style={{marginBottom: '5px', color: 'var(--accent-color)'}}>Africa - World Cup Qualifying</p> */}
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                            {
                              moment(betting?.created_at).format("DD-MM-YYYY")
                            }
                        </span>
                        <span>
                            {
                              moment(betting?.created_at).format("hh:mm:ss A")
                            }
                          {/* {new Date(betting?.created_at)?.toLocaleString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: true,
                              timeZone: "UTC",
                            }
                          )} */}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className={classes["passenger-ticket__bottom"]}>
                    <div
                      className={`${classes.circle} ${classes["circle-left"]}`}
                    />
                    <div
                      className={`${classes.circle} ${classes["circle-right"]}`}
                    />
                  </div>
                </div>
                <div className={classes["passenger-ticket"]}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: value === "eng"? "8px": 0,
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "13px",
                          color: "#ffffff75",
                          marginBottom: "0",
                        }}
                      >
                        {langChange.slipId}
                      </p>
                    </div>
                    <div style={{ width: "70%" }}>
                      <p
                        style={{
                          fontSize: "13px",
                          color: "#ffffffad",
                          marginBottom: "0",
                          wordBreak: "break-all",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          fontWeight: "bolder",
                        }}
                      >
                        {betting?.id}
                      </p>
                    </div>
                  </div>
                  {betting?.selected_matches?.length === 1 && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: value === "eng"? "8px": 0,
                      }}
                    >
                      <div style={{ width: "30%" }}>
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "13px",
                            color: "#ffffff75",
                            marginBottom: "0",
                          }}
                        >
                          {langChange.teams}
                        </p>
                      </div>
                      <div style={{ width: "70%" }}>
                        <p
                          style={{
                            fontWeight: "bolder",
                            fontSize: "13px",
                            color: "#ffffffad",
                            marginBottom: "0",
                            wordBreak: "break-all",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div>
                            <span style={{color:  (betting?.selected_matches[0]?.odds_team === "home" && betting?.selected_matches[0]?.team !== "over" && betting?.selected_matches[0]?.team !== "under") ?  'var(--accent-color)':'#ffffffad'}}>
                              {(value === "unicode" || value === "zawgyi") ? (betting?.selected_matches[0]?.match_data?.host_team_data?.name_mm || betting?.selected_matches[0]?.match_data?.host_team_data?.name_en): betting?.selected_matches[0]?.match_data?.host_team_data?.name_en} 
                            </span>
                            <span
                              style={{
                                color: "#E66F2D",
                                margin: "0 5px",
                                minWidth: "17px",
                                maxWidth: "17px",
                                textAlign: "center",
                                display: "inline-block",
                              }}
                            >
                              Vs
                            </span>
                            <span style={{color:  (betting?.selected_matches[0]?.odds_team === "away" && betting?.selected_matches[0]?.team !== "over" && betting?.selected_matches[0]?.team !== "under") ? 'var(--accent-color)':'#ffffffad'}}>
                              {(value === "unicode" || value === "zawgyi") ? (betting?.selected_matches[0]?.match_data?.guest_team_data?.name_mm || betting?.selected_matches[0]?.match_data?.guest_team_data?.name_en): betting?.selected_matches[0]?.match_data?.guest_team_data?.name_en} 
                            </span>
                          </div>
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: value === "eng"? "8px": 0,
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "13px",
                          color: "#ffffff75",
                          marginBottom: "0",
                        }}
                      >
                        {langChange.bet_type}
                      </p>
                    </div>
                    <div style={{ width: "70%" }}>
                      <p
                        style={{
                          fontWeight: "bolder",
                          fontSize: "13px",
                          color: "#ffffffad",
                          marginBottom: "0",
                          wordBreak: "break-all",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {
                          betting?.selected_matches?.length > 1 ? (
                            `${((value === "unicode" || value === "zawgyi")? 'မောင်း':"Parlay")} ( ${betting?.selected_matches?.length} )`
                          ) : (
                            <>
                              { 
                                betting?.selected_matches[0]?.market === "body" &&
                                `
                                ${
                                  (betting?.selected_matches[0]?.team === "home"? 
                                  ((value === "unicode" || value === "zawgyi") ? (betting?.selected_matches[0]?.match_data?.host_team_data?.name_mm || betting?.selected_matches[0]?.match_data?.host_team_data?.name_en): betting?.selected_matches[0]?.match_data?.host_team_data?.name_en):
                                  '')
                                }
                                ${
                                  (betting?.selected_matches[0]?.team === "away"? 
                                  ((value === "unicode" || value === "zawgyi") ? (betting?.selected_matches[0]?.match_data?.guest_team_data?.name_mm || betting?.selected_matches[0]?.match_data?.guest_team_data?.name_en): betting?.selected_matches[0]?.match_data?.guest_team_data?.name_en):
                                  '')
                                }
                                ( ${
                                  betting
                                    ?.selected_matches[0]
                                    ?.match_stage ===
                                  "first_half"
                                    ?((value === "unicode" || value === "zawgyi")? 'ပပိုင်း':"Half Time")
                                    : ((value === "unicode" || value === "zawgyi")? 'ပွဲပြီး':"Full Time")
                                } )
                                `}
                              {
                                betting?.selected_matches[0]?.market === "total" &&
                                `
                                  ${((value === "unicode" || value === "zawgyi")? (betting?.selected_matches[0]?.team === "under"? "ဂိုးအောက်": "ဂိုးပေါ်"): `${betting?.selected_matches[0]?.team}`)}
                                  ( ${
                                    betting
                                      ?.selected_matches[0]
                                      ?.match_stage ===
                                    "first_half"
                                    ?((value === "unicode" || value === "zawgyi")? 'ပပိုင်း':"Half Time")
                                    : ((value === "unicode" || value === "zawgyi")? 'ပွဲပြီး':"Full Time")
                                  } )
                                `
                              }
                              {
                                betting?.selected_matches[0]?.market ===
                                "one_x_two" &&
                                `
                                  ${capitalizedText(
                                    betting?.selected_matches[0]?.team
                                  )} 
                                  ( 1 X 2 )
                                `
                              }
                              {
                                betting?.selected_matches[0]?.market ===
                                "correct_score" &&
                                `
                                  ${capitalizedText(
                                    betting?.selected_matches[0]?.team
                                  )} 
                                  ${((value === "unicode" || value === "zawgyi")? ` ( ဂိုးခန့်မှန်း )`: ` ( Correct Score )`)}
                                `
                              }
                              {betting?.selected_matches[0]?.market ===
                                "odd_even" &&
                                `
                                  ${capitalizedText(
                                    betting?.selected_matches[0]?.team
                                  )} 
                                  ${((value === "unicode" || value === "zawgyi")? ` ( စုံ / မ )`: ` ( Even / Odd )`)}
                                `
                              }
                            </>
                          )

                          // `${capitalizedText(betting?.selected_matches[0]?.team)} ( ${betting?.selected_matches[0]?.match_stage === "first_half"? "First Half": "Full Time"} )`
                        }
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: value === "eng"? "8px": 0,
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "13px",
                          color: "#ffffff75",
                          marginBottom: "0",
                        }}
                      >
                        {langChange.bet_amount}
                      </p>
                    </div>
                    <div style={{ width: "70%" }}>
                      <p
                        style={{
                          fontWeight: "bolder",
                          fontSize: "13px",
                          marginBottom: "0",
                          wordBreak: "break-all",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <span 
                          style={{
                              color: betting?.betting_transaction_id ? '#007aff' :'#ffffffad',
                              textDecoration: betting?.betting_transaction_id ? 'underline': 'none',
                              cursor: betting?.betting_transaction_id ? 'pointer': 'normal'
                          }} 
                          onClick={(e)=> {e.stopPropagation();walletDetailHandler(betting?.betting_transaction_id)}}>
                          {betting?.amount?.toLocaleString("en-US")}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: value === "eng"? "8px": 0,
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "13px",
                          color: "#ffffff75",
                          marginBottom: "0",
                        }}
                      >
                        {langChange.payout}
                      </p>
                    </div>
                    <div style={{ width: "70%" }}>
                      <p
                        style={{
                          fontWeight: "bolder",
                          fontSize: "13px",
                          marginBottom: "0",
                          wordBreak: "break-all",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* {
                                            betting?.status === "completed" ? betting?.payout_amount?.toLocaleString("en-US"): '-'
                                        } */}
                        <span
                          style={{
                            color: betting?.reward_transaction_id ? '#007aff' :'#ffffffad',
                            textDecoration: betting?.reward_transaction_id ? 'underline': 'none',
                            cursor: betting?.reward_transaction_id ? 'pointer': 'normal'
                          }}
                          onClick={(e)=> {e.stopPropagation();walletDetailHandler(betting?.reward_transaction_id)}}
                        >
                          {betting?.bet_result === "unsettled"
                          ? "-"
                          : betting?.payout_amount?.toLocaleString("en-US")}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: value === "eng"? "8px": 0,
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "13px",
                          color: "#ffffff75",
                          marginBottom: "0",
                        }}
                      >
                        {langChange.profit}
                      </p>
                    </div>
                    <div style={{ width: "70%" }}>
                      <p
                        style={{
                          fontWeight: "bolder",
                          fontSize: "13px",
                          color: "#ffffffad",
                          marginBottom: "0",
                          wordBreak: "break-all",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* {betting?.status === "completed" ? (betting?.payout_amount - betting?.amount)?.toLocaleString("en-US"): '-' } */}
                        {betting?.bet_result === "unsettled"
                          ? "-"
                          : (
                              betting?.payout_amount - betting?.amount
                            )?.toLocaleString("en-US")}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "13px",
                          color: "#ffffff75",
                          marginBottom: "0",
                        }}
                      >
                        {langChange.win_lose}
                      </p>
                    </div>
                    <div style={{ width: "70%" }}>
                      {betting?.bet_result === "win" && (
                        <p
                          style={{
                            fontWeight: "bolder",
                            fontSize: "13px",
                            color: "#33d933",
                            marginBottom: "0",
                            wordBreak: "break-all",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <BiSolidCheckCircle
                            size={16}
                            style={{ marginRight: "5px" }}
                          />{" "}
                          Win
                        </p>
                      )}
                      {betting?.bet_result === "lose" && (
                        <p
                          style={{
                            fontWeight: "bolder",
                            fontSize: "13px",
                            color: "tomato",
                            marginBottom: "0",
                            wordBreak: "break-all",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <BiSolidMinusCircle
                            size={16}
                            style={{ marginRight: "5px" }}
                          />{" "}
                          Lose
                        </p>
                      )}
                      {betting?.bet_result === "rejected" && (
                        <p
                          style={{
                            fontWeight: "bolder",
                            fontSize: "13px",
                            color: "tomato",
                            marginBottom: "0",
                            wordBreak: "break-all",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <BiSolidMinusCircle
                            size={16}
                            style={{ marginRight: "5px" }}
                          />{" "}
                           Rejected
                        </p>
                      )}
                      {betting?.bet_result === "unsettled" && (
                        <p
                          style={{
                            fontWeight: "bolder",
                            fontSize: "13px",
                            color: "#00b5ff",
                            marginBottom: "0",
                            wordBreak: "break-all",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          Unsettled
                        </p>
                      )}
                    </div>
                  </div>

                  <div className={classes["passenger-ticket__bottom"]}>
                    <div
                      className={`${classes.circle} ${classes["circle-left"]}`}
                    />
                    <div
                      className={`${classes.circle} ${classes["circle-right"]}`}
                    />
                  </div>
                </div>
                <div className={classes["passenger-ticket"]}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "13px",
                          color: "#ffffff75",
                          marginBottom: "0",
                        }}
                      >
                        {langChange.status}
                      </p>
                    </div>
                    <div style={{ width: "70%" }}>
                      {betting?.status === "pending" && (
                        <p
                          style={{
                            fontWeight: "bolder",
                            fontSize: "13px",
                            color: "var(--accent-color)",
                            marginBottom: "0",
                            wordBreak: "break-all",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          Pending
                        </p>
                      )}
                      {betting?.status === "processing" && (
                        <p
                          style={{
                            fontWeight: "bolder",
                            fontSize: "13px",
                            color: "var(--accent-color)",
                            marginBottom: "0",
                            wordBreak: "break-all",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          Processing
                        </p>
                      )}
                      {betting?.status === "completed" && (
                        <p
                          style={{
                            fontWeight: "bolder",
                            fontSize: "13px",
                            color: "#33d933",
                            marginBottom: "0",
                            wordBreak: "break-all",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          Paid Out
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={classes["passenger-ticket__bottom"]}>
                    <div
                      className={`${classes.circle} ${classes["circle-left"]}`}
                    />
                    <div
                      className={`${classes.circle} ${classes["circle-right"]}`}
                    />
                  </div>
                </div>
              </div>
            ))
          : !loading && <NotFound text={langChange.notfound} />}
      </div>
      {createPortal(
        loading && <Loader />,
        document.getElementById("loading") as HTMLElement
      )}
    </>
  );
};

export default BetHistory;
