import CryptoJS from "crypto-js";
import { Navigate } from "react-router-dom";
import downloadjs from "downloadjs";
import moment from "moment";
import { FetchTaxPercent } from "../services/bettingService";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
const htmlToImage = require("html-to-image");
const songUrl = require("../assets/songs/song3.wav");
let pressSong = new Audio(songUrl);

export const encodeAuth = (auth) => {
  const deText = CryptoJS.AES.encrypt(
    JSON.stringify(auth),
    process.env.REACT_APP_SECRET_KEY
  ).toString();
  return deText;
};

export const decodeAuth = (auth) => {
  if (auth) {
    try {
      const bytes = CryptoJS.AES.decrypt(
        auth,
        process.env.REACT_APP_SECRET_KEY
      );
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    } catch (err) {
      console.log(err);
      const getFromStore = Cookies.get("=d;£6792)Dr!");
      if (getFromStore) {
        Cookies.remove("=d;£6792)Dr!");
        return <Navigate to="/sign-in" />;
      } else {
        return <Navigate to="/sign-in" />;
      }
    }
  }
};

export const LogoutHandle = (navigate) => {
  Cookies.remove("=d;£6792)Dr!");
  navigate("/sign-in");
};

export const replaceZeotoQqual = (str) => {
  if (str) {
    if (str.includes("+")) {
      let splitVal = str.split("+");

      if (splitVal[0] === "0" && splitVal[1] === "0") {
        splitVal[0] = "0";
        splitVal[1] = "=";
      } else {
        if (splitVal[0] === "0") {
          splitVal[0] = "=";
        } else {
          splitVal[0] = `${splitVal[0]}`;
        }
        if (splitVal[1] === "0") {
          splitVal[1] = "=";
        } else {
          splitVal[1] = `+${splitVal[1]}`;
        }
      }
      return splitVal;
    }
    if (str.includes("=")) {
      let splitVal = str.split("=");
      if (splitVal[0] === "0") {
        splitVal[0] = "";
      } else {
        splitVal[0] = `${splitVal[0]}=`;
      }
      if (splitVal[1] === "0") {
        splitVal[1] = "";
      } else {
        splitVal[1] = `${splitVal[1]}`;
      }
      return splitVal;
    }

    if (str.includes("-")) {
      let splitVal = str.split("-");
      if (splitVal[0] === "0" && splitVal[1] === "0") {
        splitVal[0] = "0";
        splitVal[1] = "=";
      } else {
        if (splitVal[0] === "0") {
          splitVal[0] = "=";
        } else {
          splitVal[0] = `${splitVal[0]}`;
        }
        if (splitVal[1] === "0") {
          splitVal[1] = "=";
        } else {
          splitVal[1] = `-${splitVal[1]}`;
        }
      }
      return splitVal;
    }

    switch (str) {
      case "0":
        return "D";
      case "1":
        return "1=";
      case "2":
        return "2=";
      case "3":
        return "3=";
      case "4":
        return "4=";
      case "5":
        return "5=";
      default:
        return str;
    }
  }
};

export const touchSong = () => {
  return pressSong.play();
};

export const capitalizedText = (text) => {
  if (text) {
    let words = text.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

    let capitalized = words.join(" ");
    return capitalized;
  }
};

export const htmlToPng = async (saveDom, name) => {
  if (saveDom) {
    // htmlToImage.toPng(saveDom).then(function (dataUrl) {
    //   downloadjs(dataUrl, `${name}.png`);
    // });
    let downloadDom = await htmlToImage.toPng(saveDom);
    const link = document.createElement("a");
    link.download = `${name}.png`;
    link.href = downloadDom;
    link.click();
  }
};

export const dateFormat = (date) => {
  const [month, day, year] = new Date(date)
    ?.toLocaleString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: "UTC",
    })
    .split("/");
  return `${day}-${month}-${year}`;
};

export const getTodayDate = (startDate, endDate) => {
  let current_hour = moment().format("HH:mm");
  let start_date = moment(startDate)
    .startOf("day")
    .add({ hours: 10, minutes: 29 })
    .format("YYYY-MM-DD HH:mm:ss");
  let end_date = moment(endDate)
    .startOf("day")
    .add({ days: 1, hours: 10, minutes: 28 })
    .format("YYYY-MM-DD HH:mm:ss");
  if (current_hour < "10:29") {
    start_date = moment(startDate)
      .startOf("day")
      .subtract({ days: 1 })
      .add({ hours: 10, minutes: 29 })
      .format("YYYY-MM-DD HH:mm:ss");
    end_date = moment(endDate)
      .startOf("day")
      .add({ hours: 10, minutes: 28 })
      .format("YYYY-MM-DD HH:mm:ss");
  }
  return { start_date, end_date };
};

export const calculatePotentialWin = (betting,tax_percent_data) => {
  if(!betting || betting==undefined){
    return 0;
  }
  let bet_amount = betting?.amount;
  let bet_fixtures = betting?.matches;
  let total_odds = 1;
  bet_fixtures?.forEach((bet) => {
    let bet_odds = _getOdds({
      fixture: bet.fixture,
      market: bet.market,
      match_stage: bet.match_stage,
      bet_team: bet.team,
    });
    // console.log("bet_odds",bet_odds);
    if (/[+\-=]/.test(bet_odds)) {
      // check mm_odds or not,
      total_odds *= 2;
    } else {
      total_odds *= bet_odds;
    }
    // console.log("total_odds",total_odds);
  });
  let bingo = bet_amount * total_odds;
  bingo = bingo.toFixed(0);
  let potentialWin = _reduceAmount({
    bingo:BigInt(bingo),
    bet_amount:BigInt(bet_amount),
    bet_football_fixtures: bet_fixtures,
    tax_percent_data:tax_percent_data
  });
  // console.log("potentialWin",potentialWin)
  return potentialWin.toString();
};

let _getOdds = ({ fixture, market, match_stage, bet_team }) => {
  // console.log("searching odds");
  let bet_odds = 0;
  if (market == "correct_score") {
    let odds = fixture?.correct_scores[match_stage];
    bet_odds = odds.find(
      (odd) => odd.name == bet_team && odd.header != 2
    )?.odds;
    // console.log("bet_odds for cs ",bet_odds);
  } else {
    let odds = fixture?.odds[match_stage];
    if (market == "total") {
      bet_odds = odds?.ou_mm_odds;
    }
    if (market == "body") {
      bet_odds = odds?.hdp_mm_odds;
    }
    if (market == "odd_even") {
      bet_odds = bet_team == "odd" ? odds?.odd : odds?.even;
    }
    if (market == "one_x_two") {
      bet_odds =
        bet_team == "home"
          ? odds?.one
          : bet_team == "draw"
          ? odds?.x
          : odds?.two;
    }
    // console.log(`market ${market} bet_odds`,bet_odds);
  }
  return bet_odds;
};

let _reduceAmount = ({ bingo, bet_amount, bet_football_fixtures,tax_percent_data }) => {
  // if(bet_football_fixtures==undefined || bet_football_fixtures?.length==0){
  //   return 0;
  // }
  let payout_amount = bingo;
  let bet_type = bet_football_fixtures?.length > 1 ? "parlay" : "single";
  let tax_percent = _getTaxPercent({
    bet_type,
    bet_football_fixtures,
    tax_percent_data
  });
  if (bet_type == "single") {
    // body ဆို အမြတ် ထဲက tax ကောက်
    let profit = bingo - bet_amount;
    if (profit > 0) {
      let tax_percent_amount =
        _safeMultiply({ num: tax_percent, bigInt: profit }) / BigInt(100);
      payout_amount = bingo - tax_percent_amount;
    }
  }
  if (bet_type == "parlay") {
    // perlay ဆို အနိုင်ငွေ ထဲက tax ကောက်
    // let bingo = bingo;
    if (bingo > 0n) {
      let tax_percent_amount =
        _safeMultiply({ num: tax_percent, bigInt: bingo }) / BigInt(100);
      payout_amount = bingo - tax_percent_amount;
    }
  }
  return payout_amount;
};

let _getTaxPercent = ({ bet_type, bet_football_fixtures,tax_percent_data }) => {
  // const {
  //   data: taxObj,
  //   error: taxPercentError,
  // } = FetchTaxPercent({
  //   payload: null,
  //   pollInterval: 1000 * 10,
  //   isLazy: false,
  // });
  // console.log("tax_percent_data",tax_percent_data)
  let tax_percent = 0;
  if(tax_percent_data){
    let tax_percents = tax_percent_data?.taxPercents;
    const findTaxPercent = (criteria) =>
      tax_percents?.find((tax) =>
        Object.keys(criteria).every((key) => tax[key] === criteria[key])
      )?.percent;
  
    const {
      match_stage,
      market,
      fixture:football_fixture,
    } = bet_football_fixtures[0];
    const is_popular_match = football_fixture.is_popular_match;
  
    if (bet_type === "single") {
      const baseCriteria = {
        min_parlay_count: 1,
        max_parlay_count: 1,
        match_stage,
      };
      const marketMapping = {
        body: "mm_odds",
        total: "mm_odds",
        one_x_two: "one_x_two",
        correct_score: "correct_score",
        odd_even: "odd_even",
      };
      const marketKey = marketMapping[market];
      // console.log("market@tax",market)
      // console.log("marketKey@tax",marketKey)
      if (marketKey) {
        const criteria = {
          ...baseCriteria,
          market: marketKey,
          is_popular_match:
            market === "body" || market === "total" ? is_popular_match : null,
        };
        // console.log("criteria", criteria);
        tax_percent = findTaxPercent(criteria);
      }
    } else {
      const parlay_count = bet_football_fixtures.length;
      const parlayRanges = [
        { min: 2, max: 2 },
        { min: 3, max: 8 },
        { min: 9, max: 11 },
      ];
      const range = parlayRanges.find(
        ({ min, max }) => parlay_count >= min && parlay_count <= max
      );
  
      if (range) {
        tax_percent = findTaxPercent({
          min_parlay_count: range.min,
          max_parlay_count: range.max,
        });
      }
    }
  }
 
  // return 10;
  return tax_percent;
};

let _safeMultiply = ({ num, bigInt }) => {
  // console.log("typeof num: " + typeof num);
  // console.log("typeof bigInt: " + typeof bigInt);
  // Handle null case by treating null as 0
  if (num === null) {
    console.log("null");
    return 0n; // Treat null as 0
  }

  // Handle case where num is already a BigInt
  if (typeof num === "bigint") {
    // console.log("bigint")
    return num * bigInt;
  }

  // Handle case where num is a number (decimal or integer)
  if (typeof num === "number") {
    // Determine the number of decimal places
    const decimalPlaces = num.toString().includes(".")
      ? num.toString().split(".")[1].length
      : 0;
    // console.log("number***",decimalPlaces)

    // Convert the decimal number to an integer representation
    const integerRepresentation = BigInt(
      Math.round(num * Math.pow(10, decimalPlaces))
    );
    // console.log("number***",integerRepresentation)
    // console.log("typeof integerRepresentation: " + typeof integerRepresentation)
    // console.log("typeof bigInt: " + typeof bigInt)
    // Perform the multiplication
    const result = integerRepresentation * bigInt;
    // console.log("result***",result)
    // console.log("typeof result: " + typeof result)
    // Adjust the result to correct the decimal places
    const finalResult = result / BigInt(Math.pow(10, decimalPlaces));
    // console.log("finalResult***",finalResult)
    // console.log("typeof finalResult: " + typeof finalResult)
    return finalResult;
  }
};

export const WeekDates = () => {
  const dates = [];
  const startOfWeek = moment();
  for (let i = 0; i < 7; i++) {
    dates.unshift(startOfWeek.clone().subtract(i, 'days').format('YYYY-MM-DD'));
  }
  return dates;
};

export const storeDataWithExpiry = (key, value, time) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + time,
  };

  let encodeData = encodeAuth(item);
  localStorage.setItem(key, encodeData);
};

export const getDataWithExpiry = (key) => {
  const getFromStore = localStorage.getItem(key);
  if (!getFromStore) {
    return [];
  };

  let decodeData = decodeAuth(getFromStore);
  const now = new Date();

  if (now.getTime() > decodeData.expiry) {
    localStorage.removeItem(key);
    return [];
  }

  return decodeData.value;
};



// full screen

export const goFullScreen = () => {
  const element = document.documentElement;
  if (element?.requestFullscreen) {
    element?.requestFullscreen();
  } else if (element?.mozRequestFullScreen) {
    element?.mozRequestFullScreen();
  } else if (element?.webkitRequestFullscreen) {
    element?.webkitRequestFullscreen();
  } else if (element?.msRequestFullscreen) {
    element?.msRequestFullscreen();
  }
};

export const exitFullScreen = () => {
  if (document?.exitFullscreen) {
    document?.exitFullscreen();
  } else if (document?.mozCancelFullScreen) { 
    document?.mozCancelFullScreen();
  } else if (document?.webkitExitFullscreen) {
    document?.webkitExitFullscreen();
  } else if (document?.msExitFullscreen) {
    document?.msExitFullscreen();
  }
};