import { createSlice } from '@reduxjs/toolkit'

export interface filterState {
  filter: any
}

const initialState: filterState = {
  filter: {
     leagues: [],
     checkAll: true,
  },
}

export const filterSettingSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    filterHandler: (state, action: any) => {
        state.filter = action.payload
    },
    
  },
})

export const { filterHandler } = filterSettingSlice.actions

export default filterSettingSlice.reducer