import { createSlice } from '@reduxjs/toolkit'

export interface leagueState {
  leagues: any
}

const initialState: leagueState = {
  leagues: [],
}

export const leaguesSlice = createSlice({
  name: 'leagues',
  initialState,
  reducers: {
    leaguesHandler: (state, action: any) => {
        state.leagues = action.payload
    },
    
  },
})

export const { leaguesHandler } = leaguesSlice.actions

export default leaguesSlice.reducer