import React from 'react'
import Layout from '../../layout/Layout'
import BetHistoryDetail from '../../components/Football/BetHistory/BetHistoryDetail'
import { langChange } from '../../lang'

const BetHistoryDetailPage = () => {
  return (
    <Layout type='' title={langChange.slips} filter="" showHeader={true} showFooter={false} home={false} showDate={true} download={true} showWallet={false} search={false}>
        <BetHistoryDetail />
    </Layout>
  )
}

export default BetHistoryDetailPage
