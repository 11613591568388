import React, { useState } from 'react';
import classes from './Footer.module.css';
import { BsBellFill } from "react-icons/bs";
import { RiHome5Fill } from "react-icons/ri";
import { IoGameController } from "react-icons/io5";
import { TiVideo } from "react-icons/ti";
import { BiSolidUserPin } from "react-icons/bi";
import { useLocation, useNavigate } from 'react-router-dom';
import { touchSong } from '../../utils/Helper';
import { message } from 'antd';
import { MdMenuBook } from 'react-icons/md';
import { langChange } from '../../lang';

const Footer = () => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const navigateHandler = (url:string) => {
    touchSong();
    navigate(url);
  };

  const alertMessage = (status:any, msg:any) => {
        messageApi.open({
        // key: 'coming_soon',
        type: status,
        content: msg,
        });
  };

  const notFoundHandler = () => {
    return alertMessage('warning',"Coming Soon!");
  }
  

  return (
    <>
        {contextHolder}
        <footer className={classes.footer}>
            <ul className={classes.tab}>
                <li>
                    <button onClick={() => navigateHandler('/')}>
                        <RiHome5Fill color={pathname === '/'? `var(--accent-color)`: '#FFF'} size="20px" style={{marginTop: '10px'}}  />
                        <p>{langChange.home}</p>
                    </button>
                </li>
                <li>
                    <button onClick={notFoundHandler}>
                        <MdMenuBook color={pathname === '/guild'? `var(--accent-color)`: '#FFF'} size="20px" style={{marginTop: '10px'}} />
                        <p>{langChange.app_guild}</p>
                    </button>
                </li>
                {/* <li>
                    <button onClick={() => navigateHandler('/games')}>
                        <IoGameController color={pathname === '/games'? `var(--accent-color)`: '#FFF'} size="20px" style={{marginTop: '10px'}} />
                        <p>ဂိမ်းများ</p>
                    </button>
                </li> */}
                <li>
                    <button onClick={notFoundHandler}>
                        <BsBellFill color={pathname === '/notification'? `var(--accent-color)`: '#FFF'} size="20px" style={{marginTop: '10px'}} />
                        <p>{langChange.notification}</p>
                    </button>
                </li>
                <li>
                    <button onClick={() => navigateHandler('/profile')}>
                        <BiSolidUserPin color={pathname === '/profile'? `var(--accent-color)`: '#FFF'} size="20px" style={{marginTop: '10px'}} />
                        <p>{langChange.profile}</p>
                    </button>
                </li>
            </ul>
        </footer>
    </>
  )
}

export default Footer
