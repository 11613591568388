import { createSlice } from "@reduxjs/toolkit";

const initialState:any = {
    refreshStatus: true,
}

const refreshBalanceSlice = createSlice({
    name: 'refreshStatus',
    initialState,
    reducers: {
        refetchHandler: (state,action) => {
            state.refreshStatus = action.payload
        }
    }
});

export const {refetchHandler} = refreshBalanceSlice.actions;
export default refreshBalanceSlice.reducer;