import React, { useEffect, useState } from 'react'
import classes from './BetHistory.module.css';
import { BiSolidCheckCircle, BiSolidMinusCircle } from 'react-icons/bi';
import { IoIosFootball } from 'react-icons/io';
import { GET_BET_LIST_DATA_SERVICE, GET_GOALS_DATA_SERVICE } from '../../../services/graphql/queryService';
import { GET_BET_LIST_DATA, GET_GOAL_DATA } from '../../../services/graphql/queries';
import { useNavigate, useParams } from 'react-router-dom';
import { capitalizedText, dateFormat, LogoutHandle, replaceZeotoQqual } from '../../../utils/Helper';
import moment from 'moment';
import { Badge } from 'antd';
import { TEAM_IMAGE } from '../../../services/apis';
import { useSelector } from 'react-redux';
import { langChange } from '../../../lang';
const noImage = require('../../../assets/images/icons/no-image.png');

const BetHistoryDetail = () => {
  const params = useParams();
  const betListQuery = GET_BET_LIST_DATA_SERVICE(GET_BET_LIST_DATA, params?.id);
  const [isLoading,setIsLoading] = useState(true);
  const [betting,setBetting] = useState<any>(null);
  const navigate = useNavigate();
  const {value} = useSelector((state:any) => state.lang)

  useEffect(()=>{
    betListQuery?.refetch();
  },[params]);

  useEffect(()=>{
    setIsLoading(betListQuery?.loading);
    if (betListQuery?.error?.message === "Not authenticated" || betListQuery?.error?.message.includes("Invalid token") || betListQuery?.error?.message.includes("Unauthorized token") || betListQuery?.error?.message.includes("Not Authorised!")) {
        return LogoutHandle(navigate);
    };
    if(betListQuery?.data && betListQuery?.data?.betting){
        setBetting(betListQuery?.data?.betting);
    };
  },[betListQuery]);

  const liveGoals = (data:any) => {
        if(data?.match_stage === "full_time"){
            if(data?.match_data?.ft_status === "completed"){
                return  <span style={{height: '40px', lineHeight: '40px',padding: '0 3px',fontSize: '16px',color: '#E66F2D',fontWeight: 'bolder'}}>
                            {data?.match_data?.ft_goals?.home}&nbsp;&nbsp;:&nbsp;&nbsp;{data?.match_data?.ft_goals?.away}
                        </span>
            } else if(data?.match_data?.live_goals){
                return  <span style={{height: '40px', lineHeight: '40px',padding: '0 3px',fontSize: '16px',color: '#E66F2D',fontWeight: 'bolder'}}>
                            {data?.match_data?.live_goals?.home}&nbsp;&nbsp;:&nbsp;&nbsp;{data?.match_data?.live_goals?.away}
                        </span>
            }else {
                return  <span style={{height: '40px', lineHeight: '40px',padding: '0 3px',fontSize: '15px',color: '#E66F2D'}}>
                            Vs
                        </span>
            };
        }else{
            if(data?.match_data?.fh_status === "completed"){
                return  <span style={{height: '40px', lineHeight: '40px',padding: '0 3px',fontSize: '16px',color: '#E66F2D',fontWeight: 'bolder'}}>
                            {data?.match_data?.fh_goals?.home}&nbsp;&nbsp;:&nbsp;&nbsp;{data?.match_data?.fh_goals?.away}
                        </span>
            } else if(data?.match_data?.live_goals){
                return  <span style={{height: '40px', lineHeight: '40px',padding: '0 3px',fontSize: '16px',color: '#E66F2D',fontWeight: 'bolder'}}>
                            {data?.match_data?.live_goals?.home}&nbsp;&nbsp;:&nbsp;&nbsp;{data?.match_data?.live_goals?.away}
                        </span>
            }else {
                return  <span style={{height: '40px', lineHeight: '40px',padding: '0 3px',fontSize: '15px',color: '#E66F2D'}}>
                            Vs
                        </span>
            };
        };
        
  };


  const _showOddsOnBet = ({bet_team,odds_team,odds}:any) => {
        if(odds[1] === "="){
            return odds;
        }else{
            if(bet_team === odds_team){
                return odds;
            }else{
                if(Number(odds[1]) > 0){
                    return `${odds[0]}-${Math.abs(odds[1])}`
                }else{
                    return `${odds[0]}+${Math.abs(odds[1])}` 
                };
            }
        };   
  };

  const _showBetTeamColor = ({market,bet_team,odds_team}:any) => {
    // console.log(`market: ${market}, bet_team: ${bet_team}, odds_team: ${odds_team}`)
    let color_code = "";
    if(market == 'body'){
        if(bet_team == odds_team){
            color_code = "tomato";
        }else{
            color_code = "rgb(0, 181, 255)";
        }
    }
    if(market == 'total'){
        if(bet_team == "over"){
            color_code = "tomato";
        }else{
            color_code = "rgb(0, 181, 255)";
        }
    }
    return color_code;
  }

  const _showBetType = ({market,match_stage}:any) => {
    if(market == "body" || market=="total"){
        return ` ( ${match_stage === "first_half"? ((value === "unicode" || value === "zawgyi")? 'ပပိုင်း':"Half Time"): ((value === "unicode" || value === "zawgyi")? 'ပွဲပြီး':"Full Time")} )`
    }
    if(market == "one_x_two"){
        return ` ( 1 X 2 )`
    }
    if(market == "correct_score"){
        return ((value === "unicode" || value === "zawgyi")? ` ( ဂိုးခန့်မှန်း )`: ` ( Correct Score )`)
    }
    if(market=="odd_even"){
        return ((value === "unicode" || value === "zawgyi")? ` ( စုံ / မ )`: ` ( Even / Odd )`)
    }
  };

  return (
    <div id='saveToImg' className={classes.passenger}>
        <div className={classes['passenger-ticket']}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '13px',
            }}>
                <IoIosFootball size={20} style={{marginRight: '7px'}}/>
                <div style={{width: '100%'}}>
                    <p
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                    >
                        <span>
                            {
                              moment(betting?.created_at).format("DD-MM-YYYY")
                            }
                        </span>
                        <span>
                            {
                              moment(betting?.created_at).format("hh:mm:ss A")
                            }
                        </span>
                    </p>
                </div>
            </div>
            <div className={classes['passenger-ticket__bottom']}>
                <div className={`${classes.circle} ${classes['circle-left']}`} />
                <div className={`${classes.circle} ${classes['circle-right']}`} />
            </div>
        </div>
        {
            betting?.selected_matches?.map((bet:any) => 
                {
                    return <div className={classes['passenger-ticket']} key={bet?.id}>
                                <p style={{marginBottom: '5px', color: 'var(--accent-color)', fontSize: '13px'}}>
                                    {
                                        bet?.match_data?.league_data?.name_en
                                    }
                                </p>
                                <div className={classes.team_container}>
                                    <div className={classes.teamH}>
                                        <span style={{color:  (bet?.odds_team === "home" && bet?.team !== "over" && bet?.team !== "under") ?  'var(--accent-color)':'#ffffffad'}}>
                                            {(value === "unicode" || value === "zawgyi") ? (bet?.match_data?.host_team_data?.name_mm || bet?.match_data?.host_team_data?.name_en): bet?.match_data?.host_team_data?.name_en}
                                        </span>
                                        <span className={classes.teamLogoBg}>
                                            {
                                                <img src={TEAM_IMAGE(bet?.match_data?.host_team_data)} />
                                            }
                                        </span>
                                    </div>
                                    {
                                        liveGoals(bet)
                                    }

                                        {/* <span style={{height: '40px', lineHeight: '40px',padding: '0 3px',fontSize: '15px',color: 'var(--accent-color)'}}>
                                            Vs
                                        </span> */}

                                        {/* <span style={{height: '40px', lineHeight: '40px',padding: '0 3px',fontSize: '16px',color: 'var(--accent-color)',fontWeight: 'bolder'}}>
                                           {'2'}&nbsp;&nbsp;:&nbsp;&nbsp;{'1'}
                                        </span> */}

                                    {/* <span style={{height: '40px', lineHeight: '40px',padding: '0 3px',fontSize: '15px',color: 'var(--accent-color)'}}>
                                        Vs
                                    </span> */}
                                    {/* <span style={{height: '40px', lineHeight: '40px',padding: '0 3px',fontSize: '16px',color: 'var(--accent-color)',fontWeight: 'bolder'}}>
                                        7&nbsp;&nbsp;:&nbsp;&nbsp;8
                                    </span> */}
                                    <div className={classes.teamA}>
                                        <span className={classes.teamLogoBg}>
                                            {
                                                <img src={TEAM_IMAGE(bet?.match_data?.guest_team_data)} />
                                            }
                                        </span>
                                        <span style={{color:  (bet?.odds_team === "away" && bet?.team !== "over" && bet?.team !== "under") ? 'var(--accent-color)':'#ffffffad'}}>
                                            {(value === "unicode" || value === "zawgyi") ? (bet?.match_data?.guest_team_data?.name_mm || bet?.match_data?.guest_team_data?.name_en): bet?.match_data?.guest_team_data?.name_en} 
                                        </span>
                                    </div>
                                </div>
                                <div style={{textAlign: 'center', margin: '5px 0', fontSize: '13px', color: '#ffffff75'}}>

                                    {
                                        `${new Date(bet?.match_data?.fixture_timestamp * 1000).toLocaleString("en-US",{
                                            timeZone: "Asia/Yangon",
                                            hour: "2-digit", 
                                            minute: "2-digit", 
                                            second: "2-digit",
                                            hour12: true
                                        })}`
                                    }
                                </div>
                                <div 
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: value === "eng"? "8px": 0,
                                    }}
                                >
                                    <div style={{width: '30%'}}>
                                        <p style={{textAlign: 'left', fontSize: '13px', color: '#ffffff75', marginBottom: '0'}}>{langChange.bet_type}</p>
                                    </div>
                                    <div style={{width: '70%'}}>
                                        {
                                            <p style={{fontWeight: 'bolder',fontSize: '13px', color: '#ffffffad', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                                <span className={classes.odds_badge}>
                                                    { replaceZeotoQqual(bet?.odds)  }
                                                </span>
                                                <span style={{color:_showBetTeamColor({market:bet?.market,bet_team:bet?.team,odds_team:bet?.odds_team})}}>
                                                    {
                                                        bet?.team === 'home'? 
                                                        ((value === "unicode" || value === "zawgyi") ? (bet?.match_data?.host_team_data?.name_mm || bet?.match_data?.host_team_data?.name_en): bet?.match_data?.host_team_data?.name_en):
                                                        ''
                                                    }
                                                    {
                                                        bet?.team === 'away'? 
                                                        ((value === "unicode" || value === "zawgyi") ? (bet?.match_data?.guest_team_data?.name_mm || bet?.match_data?.guest_team_data?.name_en): bet?.match_data?.host_team_data?.name_en):
                                                        ''
                                                    }
                                                    {
                                                        bet?.team === 'over'?
                                                        langChange.football_over: ''
                                                    }
                                                    {
                                                        bet?.team === 'under'?
                                                        langChange.football_under: ''
                                                    }
                                                    {   (bet?.team === 'home' || bet?.team === 'away' || bet?.team === 'over' || bet?.team === 'under')?
                                                        '': capitalizedText(bet?.team)
                                                    }&nbsp; 
                                                </span>
                                                <span>
                                                    {_showBetType({market:bet?.market,match_stage:bet?.match_stage})}
                                                </span>
                                            </p>
                                        }
                                    </div>
                                </div>
                                <div 
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div style={{width: '30%'}}>
                                        <p style={{textAlign: 'left', fontSize: '13px', color: '#ffffff75', marginBottom: '0'}}>{langChange.result}</p>
                                    </div>
                                    <div style={{width: '70%'}}>
                                        <p style={{ fontWeight: 'bolder', fontSize: '13px', color: '#ffffffad', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                            {
                                                bet?.status === "cancel" &&
                                                <p style={{ fontWeight: 'bolder',fontSize: '13px', color: 'tomato', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                                    <BiSolidMinusCircle size={16} style={{marginRight: '5px'}}/> Rejected
                                                </p>
                                            }
                                            {
                                                (bet?.status !== "cancel" && bet?.bet_result === "win") &&
                                                <p style={{ fontWeight: 'bolder',fontSize: '13px', color: '#33d933', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                                    <BiSolidCheckCircle size={16} style={{marginRight: '5px'}}/> Win
                                                </p>
                                            }
                                            {
                                                (bet?.status !== "cancel" && bet?.bet_result === "lose") &&
                                                <p style={{ fontWeight: 'bolder',fontSize: '13px', color: 'tomato', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                                    <BiSolidMinusCircle size={16} style={{marginRight: '5px'}}/> Lose
                                                </p>
                                            }
                                            {
                                                (bet?.status !== "cancel" && bet?.bet_result === "rejected") &&
                                                <p style={{ fontWeight: 'bolder',fontSize: '13px', color: 'tomato', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                                    <BiSolidMinusCircle size={16} style={{marginRight: '5px'}}/> Rejected
                                                </p>
                                            }
                                            {
                                                (bet?.status !== "cancel" && bet?.bet_result === "unsettled") &&
                                                <p style={{ fontWeight: 'bolder',fontSize: '13px', color: '#00b5ff', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                                    Unsettled
                                                </p>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className={classes['passenger-ticket__bottom']}>
                                    <div className={`${classes.circle} ${classes['circle-left']}`} />
                                    <div className={`${classes.circle} ${classes['circle-right']}`} />
                                </div>
                            </div>
                }
            )
        }
        <div className={classes['passenger-ticket']}>
            <div 
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: value === "eng"? "8px": 0,
                }}
            >
                <div style={{width: '30%'}}>
                    <p style={{textAlign: 'left', fontSize: '13px', color: '#ffffff75', marginBottom: '0'}}>{langChange.slipId}</p>
                </div>
                <div style={{width: '70%'}}>
                    <p style={{fontWeight: 'bolder',fontSize: '13px', color: '#ffffffad', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        {betting?.id ?? '-'}
                    </p>
                </div>
            </div>
            {
                betting?.selected_matches?.length > 1 &&
                <div 
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: value === "eng"? "8px": 0,
                    }}
                >
                    {/* <div style={{width: '30%'}}>
                        <p style={{textAlign: 'left', fontSize: '13px', color: '#ffffff75', marginBottom: '0'}}>No of bets</p>
                    </div>
                    <div style={{width: '70%'}}>
                        <p style={{fontWeight: 'bolder', fontSize: '13px', color: '#ffffffad', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            {betting?.selected_matches?.length}
                        </p>
                    </div> */}
                </div>
            }
            <div 
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: value === "eng"? "8px": 0,
                }}
            >
                <div style={{width: '30%'}}>
                    <p style={{textAlign: 'left', fontSize: '13px', color: '#ffffff75', marginBottom: '0'}}>{langChange.stake}</p>
                </div>
                <div style={{width: '70%'}}>
                    <p onClick={() => betting?.betting_transaction_id ? navigate(`/wallet-transaction/detail/${betting?.betting_transaction_id}`): {}} style={{ fontWeight: 'bolder', fontSize: '13px', color: betting?.betting_transaction_id ? '#007aff' :'#ffffffad', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textDecoration: betting?.betting_transaction_id ? 'underline': 'none',cursor: betting?.betting_transaction_id ? 'pointer': 'normal'}}>
                        {betting?.amount?.toLocaleString("en-US") ?? '-'}
                    </p>
                </div>
            </div>
            <div 
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: value === "eng"? "8px": 0,
                }}
            >
                <div style={{width: '30%'}}>
                    <p style={{textAlign: 'left', fontSize: '13px', color: '#ffffff75', marginBottom: '0'}}>{langChange.payout}</p>
                </div>
                <div style={{width: '70%'}}>
                    <p onClick={() => betting?.reward_transaction_id ? navigate(`/wallet-transaction/detail/${betting?.reward_transaction_id}`): {}} style={{ fontWeight: 'bolder', fontSize: '13px', color: betting?.reward_transaction_id ? '#007aff' :'#ffffffad', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end',textDecoration: betting?.reward_transaction_id ? 'underline': 'none',cursor: betting?.reward_transaction_id ? 'pointer': 'normal'}}>
                        {
                        //    betting?.status === "completed" ? betting?.payout_amount?.toLocaleString("en-US"): '-'
                        }
                        {betting?.bet_result === "unsettled" ? '-':betting?.payout_amount?.toLocaleString("en-US")}
                    </p>
                </div>
            </div>
            <div 
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: value === "eng"? "8px": 0,
                }}
            >
                <div style={{width: '30%'}}>
                    <p style={{textAlign: 'left', fontSize: '13px', color: '#ffffff75', marginBottom: '0'}}>{langChange.profit}</p>
                </div>
                <div style={{width: '70%'}}>
                    <p style={{fontWeight: 'bolder',fontSize: '13px', color: '#ffffffad', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        {/* {betting?.status === "completed" ? (betting?.payout_amount - betting?.amount)?.toLocaleString("en-US") : '-'} */}
                        {
                            betting?(
                                betting?.bet_result === "unsettled" ? '-' : 
                                (betting?.payout_amount - betting?.amount)?.toLocaleString("en-US")
                            ):
                            '-'  
                        }
                    </p>
                </div>
            </div>
            <div 
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: value === "eng"? "8px": 0,
                }}
            >
                <div style={{width: '30%'}}>
                    <p style={{textAlign: 'left', fontSize: '13px', color: '#ffffff75', marginBottom: '0'}}>{langChange.win_lose}</p>
                </div>
                <div style={{width: '70%'}}>
                    {
                        betting?.bet_result === "win" &&
                        <p style={{ fontWeight: 'bolder', fontSize: '13px', color: '#33d933', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <BiSolidCheckCircle size={16} style={{marginRight: '5px'}}/> Win
                        </p>
                    }
                    {
                        betting?.bet_result === "lose" &&
                        <p style={{ fontWeight: 'bolder', fontSize: '13px', color: 'tomato', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <BiSolidMinusCircle size={16} style={{marginRight: '5px'}}/> Lose
                        </p>
                    }
                    {
                        betting?.bet_result === "rejected" &&
                        <p style={{ fontWeight: 'bolder', fontSize: '13px', color: 'tomato', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            <BiSolidMinusCircle size={16} style={{marginRight: '5px'}}/> Rejected
                        </p>
                    }
                    {
                        betting?.bet_result === "unsettled" &&
                        <p style={{ fontWeight: 'bolder', fontSize: '13px', color: '#00b5ff', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            Unsettled
                        </p>
                    }
                </div>
            </div>
            <div 
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <div style={{width: '30%'}}>
                    <p style={{textAlign: 'left', fontSize: '13px', color: '#ffffff75', marginBottom: '0'}}>{langChange.status}</p>
                </div>
                <div style={{width: '70%'}}>
                    {
                        betting?.status === "pending" &&
                        <p style={{ fontWeight: 'bolder',fontSize: '13px', color: 'var(--accent-color)', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            Pending
                        </p>
                    }
                    {
                        betting?.status === "processing" &&
                        <p style={{ fontWeight: 'bolder',fontSize: '13px', color: 'var(--accent-color)', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            Processing
                        </p>
                    }
                    {
                        betting?.status === "completed" &&
                        <p style={{ fontWeight: 'bolder',fontSize: '13px', color: '#33d933', marginBottom: '0', wordBreak: 'break-all',display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                            Paid Out
                        </p>
                    }
                </div>
            </div>
            <div className={classes['passenger-ticket__bottom']}>
                <div className={`${classes.circle} ${classes['circle-left']}`} />
                <div className={`${classes.circle} ${classes['circle-right']}`} />
            </div>
        </div>
        {
            betting?.remark &&
            <div className={classes['passenger-ticket']}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '12px',
                }}>
                    <p className={classes.remarkText}>
                        {betting?.remark}
                    </p>
                </div>
                <div className={classes['passenger-ticket__bottom']}>
                    <div className={`${classes.circle} ${classes['circle-left']}`} />
                    <div className={`${classes.circle} ${classes['circle-right']}`} />
                </div>
            </div>
        }
    </div>
  )
}

export default BetHistoryDetail
