import { createSlice } from '@reduxjs/toolkit'

const initialState:any = {
    marketStatus: {
      fullTime: false,
      firstHalf: false,
      evenOdd: false,
      corretScores: false,
      onextwo: false
    },
    search: '',
}

export const marketOddsSlice:any = createSlice({
  name: 'marketOdds',
  initialState,
  reducers: {
    marketStatusHandler: (state:any, action: any) => {
        state.marketStatus = action.payload
    },
    searchFilterHandler: (state:any, action: any) => {
        state.search = action.payload
    },
    
  },
})

export const { marketStatusHandler ,searchFilterHandler} = marketOddsSlice.actions

export default marketOddsSlice.reducer