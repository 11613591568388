import React from 'react'
import Layout from '../../layout/Layout'
import LiveGoalsFav from '../../components/LiveGoals/LiveGoalsFav'
import { langChange } from '../../lang'

const LiveGoalsFavPage = () => {
  return (
    <Layout type='' title={langChange.liveGoals} filter="" showHeader={true} showFooter={false} home={false} showDate={false} download={false} showWallet={false} search={false}>
        <LiveGoalsFav />
    </Layout>
  )
}

export default LiveGoalsFavPage
