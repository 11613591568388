import { createSlice } from '@reduxjs/toolkit'

const initialState:any = {
    refresh: false,
}

export const refreshSlice:any = createSlice({
  name: 'refresh',
  initialState,
  reducers: {
    refreshHandler: (state:any, action: any) => {
        state.refresh = action.payload
    },
  },
})

export const { refreshHandler } = refreshSlice.actions

export default refreshSlice.reducer