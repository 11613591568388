import React from 'react';
import Layout from '../../layout/Layout';
import Home from '../../components/Home/Home';

const HomePage = () => {
  return (
    <Layout type="" title='' filter="" showHeader={true} showFooter={true} home={true} showDate={false} download={false} showWallet={false} search={false}>
       <Home />
    </Layout>
  )
}

export default HomePage
