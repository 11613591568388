import React, { useEffect, useState } from 'react'
import classes from './MatchsType.module.css';
import Dates from './Dates';
import { GET_RESULT_DATA_SERVICE } from '../../services/graphql/queryService';
import { GET_RESULT_DATA } from '../../services/graphql/queries';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { LogoutHandle, capitalizedText, getDataWithExpiry, storeDataWithExpiry } from '../../utils/Helper';
import NotFound from '../../utils/NotFound';
import { TEAM_IMAGE } from '../../services/apis';
import { createPortal } from 'react-dom';
import Loader from '../../utils/Loader/Loader';
import { FaRegStar, FaStar } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { langChange } from '../../lang';

const MatchsType = () => {
  const [isLoading,setIsLoading] = useState(true);
  const [matchsType,setMatchsType] = useState("inplay");
  const [filterDate,setFilterDate] = useState(moment().format("YYYY-MM-DD"));
  const [fixtures,setFixtures] = useState([]);
  const [updateFixtures,setUpdateFixtures] = useState([]);
  const [sortedFixtures,setSortedFixtures] = useState<any>([]);
  const [favMatchs,setFavMatchs] = useState<any>([]);
  const navigate = useNavigate();
  const {search} = useSelector((state:any) => state.marketOdds);
  const fixturesQuery = GET_RESULT_DATA_SERVICE(GET_RESULT_DATA,matchsType,filterDate,"desc",400,1);
  const {value} = useSelector((state:any) => state.lang);

    useEffect(()=>{
        const data = getDataWithExpiry('fav_match');
        setFavMatchs(data);
    },[])

    useEffect(()=>{
        setIsLoading(true);
    },[matchsType])

    useEffect(()=>{
        setIsLoading(fixturesQuery?.loading);
        if (fixturesQuery?.error?.message === "Not authenticated" || fixturesQuery?.error?.message.includes("Invalid token") || fixturesQuery?.error?.message.includes("Unauthorized token") || fixturesQuery?.error?.message.includes("Not Authorised!")) {
            return LogoutHandle(navigate);
        };
        if(fixturesQuery?.data && fixturesQuery?.data?.matches){
            setFixtures(fixturesQuery?.data?.matches);
        };
    },[fixturesQuery]);

    useEffect(()=>{
        const capitalized = capitalizedText(search);
        let find = fixtures?.filter((result:any) => 
          result?.fixture_start_time?.includes(search) || 
          result?.league_data?.name_en?.includes(capitalized) ||
          result?.host_team_data?.name_en?.includes(capitalized) ||
          result?.host_team_data?.name_mm?.includes(capitalized) ||
          result?.guest_team_data?.name_en?.includes(capitalized) ||
          result?.guest_team_data?.name_mm?.includes(capitalized)
        );

        if(search){
            setUpdateFixtures(find);
        }else{
            setUpdateFixtures(fixtures);
        };
    },[search,fixtures]);

    useEffect(()=>{
        let leagueNamesArr:any = [];
        if(updateFixtures.length > 0){
            updateFixtures.map((match:any) => {
              leagueNamesArr.push(match?.league_data?.name_en);
            });
        };
        let uniqueLeagueNames = new Set(leagueNamesArr);
        let uniqueLeagueNamesToArr = Array.from(uniqueLeagueNames);
        let allMatchsArr = uniqueLeagueNamesToArr.map(league => {return {league_name: league, timestamp: '', matchs : []}});

        if(updateFixtures.length > 0){
            updateFixtures.map((match:any) => {
              let findSameLeagueName:any = allMatchsArr.find(lea => lea.league_name === match.league_data?.name_en);
              if(findSameLeagueName){
                  if(findSameLeagueName.timestamp === match.fixture_timestamp){
                      findSameLeagueName.matchs.push(match);
                  }else{
                      findSameLeagueName.league_name = match?.league_data?.name_en;
                      findSameLeagueName.timestamp = match.fixture_timestamp;
                      findSameLeagueName.matchs.push(match);
                  };
              };
            })
        };
        setSortedFixtures(allMatchsArr)
    },[updateFixtures]);

    const favouriteHandler = (match:any) => {
        let copyArr = [...favMatchs];
        if(copyArr.length > 0){
            let isHas = copyArr.some(id => id === Number(match.id));
            if(isHas){
                let filterArr = copyArr.filter(id => id !== Number(match.id))
                setFavMatchs(filterArr);
            }else{
                setFavMatchs([...copyArr,Number(match?.id)]);
            };
        }else{
            setFavMatchs([...copyArr,Number(match?.id)]);
        };
    };

    useEffect(()=>{
        storeDataWithExpiry("fav_match",favMatchs, 1000 * 60 * 60 * 24);
    },[favMatchs]);

  return (
    <>
        <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column'}}>
            <Dates matchsType={matchsType} setMatchsType={setMatchsType} filterDate={filterDate} setFilterDate={setFilterDate} setIsLoading={setIsLoading} />
            <ul className={classes.tabs}>
                <li style={{padding: value === 'eng'? '5px 0':'0'}}>
                    <div>{langChange.hour}</div>
                </li>
                <li style={{padding: value === 'eng'? '5px 0':'0'}}>
                    <div>{langChange.football_teams}</div>
                </li>
                <li>
                    <div>
                        <span>
                            {
                                matchsType === "inplay" ? 'LIVE': ''  
                            }
                            {
                                matchsType === "completed" ? 'FT': ''  
                            }
                            {
                                matchsType === "first-half-complete" ? 'HT': ''
                            }
                            {
                                matchsType === "cancel" ? 'REJECT': ''
                            }
                        </span>
                    </div>
                </li>
            </ul>
            <ul className={classes.matchsContainer}>
                {
                    sortedFixtures?.length > 0 ?
                    sortedFixtures?.map((fixture:any, i:number) => {
                        return <li key={i}>
                            <div className={classes.leagueName}>
                                <span>{fixture?.league_name}</span>
                            </div>
                            {
                                fixture?.matchs?.map((data:any) => 
                                    <div key={data?.id} className={classes.teamsGroup}>
                                        <div>
                                            <div style={{marginBottom: '2px', textTransform: 'uppercase'}}>
                                                {moment(data?.fixture_start_time).format('ddd')}
                                            </div>
                                            <div>{moment(data?.fixture_start_time).format('hh:mm A')}</div>
                                        </div>
                                        <div>
                                            <div className={classes.homeTeam}>
                                                <img src={TEAM_IMAGE(data?.host_team_data)} />
                                                <div>
                                                    {(value === "unicode" || value === "zawgyi") ? (data?.host_team_data?.name_mm || data?.host_team_data?.name_en): data?.host_team_data?.name_en} 
                                                    <span style={{fontWeight: 'bolder', marginLeft: '10px', fontSize: '15px'}}>
                                                        {
                                                            matchsType === "inplay" ?
                                                            (!isLoading ? (data?.live_goals?.home == 0 ? data?.live_goals?.home : (data?.live_goals?.home || '-')): '-'): ''
                                                        }
                                                        {
                                                            matchsType === "completed" ?
                                                            (!isLoading ? (data?.ft_goals?.home == 0 ? data?.ft_goals?.home : (data?.ft_goals?.home || '-')): '-'): ''
                                                        }
                                                        {
                                                            matchsType === "first-half-complete" ?
                                                            (!isLoading ? (data?.fh_goals?.home == 0 ? data?.fh_goals?.home : (data?.fh_goals?.home || '-')): '-'): ''
                                                        }
                                                        {
                                                            matchsType === "cancel" && '-'
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={classes.awayTeam}>
                                                <img src={TEAM_IMAGE(data?.guest_team_data)} /> 
                                                <div>
                                                    {(value === "unicode" || value === "zawgyi") ? (data?.guest_team_data?.name_mm || data?.guest_team_data?.name_en): data?.guest_team_data?.name_en} 
                                                    <span style={{fontWeight: 'bolder', marginLeft: '10px', fontSize: '15px'}}>
                                                        {
                                                            matchsType === "inplay" ?
                                                            (!isLoading ? (data?.live_goals?.away == 0 ? data?.live_goals?.away : (data?.live_goals?.away || '-')): '-') : ''
                                                        }
                                                        {
                                                            matchsType === "completed" ?
                                                            (!isLoading ? (data?.ft_goals?.away == 0 ? data?.ft_goals?.away : (data?.ft_goals?.away || '-')): '-') : ''
                                                        }
                                                        {
                                                            matchsType === "first-half-complete" ?
                                                            (!isLoading ? (data?.fh_goals?.away == 0 ? data?.fh_goals?.away : (data?.fh_goals?.away || '-')): '-'): ''
                                                        }
                                                        {
                                                            matchsType === "cancel" && '-'
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                favMatchs && favMatchs?.find((el:any) => el === Number(data.id)) ?
                                                <FaStar onClick={() => favouriteHandler(data)} style={{cursor: 'pointer'}} color='var(--accent-color)' size={20} />:
                                                <FaRegStar onClick={() => favouriteHandler(data)} style={{cursor: 'pointer'}} color='var(--accent-color)' size={20} /> 
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            
                        </li>
                    }
                    )
                    :
                    !isLoading && <NotFound text={langChange.notfound} />
                }
            </ul>
        </div>
        {createPortal(
            isLoading && <Loader />,
            document.getElementById("loading") as HTMLElement
        )}
    </>
  )
}

export default MatchsType
