import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    value: localStorage.getItem('lang') || "unicode",
};

const langSlice = createSlice({
    name: 'lang',
    initialState,
    reducers: {
         langHandler: (state,action) => {
             state.value = action.payload
         }
    }
});

export const {langHandler} = langSlice.actions;
export default langSlice.reducer;