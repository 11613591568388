import React, { useEffect, useRef, useState } from 'react'
import classes from './Matchs.module.css';
import { useSelector } from 'react-redux';
import { Collapse } from 'antd';
import { TEAM_IMAGE } from '../../../services/apis';
import { langChange } from '../../../lang';
import { replaceZeotoQqual } from '../../../utils/Helper';


const ParlayMatchCard = ({index,data,setSize,windowWidth,pressHandler,selectActive,changeFtOdds,changeFhOdds}:any) => {
  const {value} = useSelector((state:any) => state.lang);
  const {marketStatus} = useSelector((state:any) => state.marketOdds);
  const rowRef:any = useRef();
  const [correctScoresOdds,setCorrectScoresOdds] = useState<any>(null);
  useEffect(() => {
      setSize(index, rowRef?.current?.getBoundingClientRect().height);
  }, [setSize, index, windowWidth]);
  return (
    <div ref={rowRef}>
        {/* <Collapse
            key={index}
            size="small"
            ghost
            defaultActiveKey={[index]}
            items={[{ key: index, label: (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <h3 className={classes.leagueName}> 
                        <span style={{color: 'var(--accent-color)'}}>{data[index]?.league_name}</span>
                    </h3>
                    <span style={{
                            display: 'block',
                            width: '18px',
                            height: '18px',
                            fontSize: '12px',
                            borderRadius: '50%',
                            backgroundColor: 'red',
                            marginLeft: '10px',
                            color: '#FFF',
                            textAlign: 'center',
                            lineHeight: '16px'
                        }}>{data[index]?.matchs.length || 0}
                    </span>
                </div>
            ), 
                children: 
                <li>
                    {
                        data[index]?.matchs.length > 0 &&
                        data[index]?.matchs?.map((fixture:any) => {
                            let homeTeamName = (value === "unicode" || value === "zawgyi") ? (fixture?.host_team_data?.name_mm || fixture?.host_team_data?.name_en) : fixture?.host_team_data?.name_en;
                            let awayTeamName = (value === "unicode" || value === "zawgyi") ? (fixture?.guest_team_data?.name_mm || fixture?.guest_team_data?.name_en) : fixture?.guest_team_data?.name_en;
                            return <div key={fixture.id} className={classes.card}>
                            <time className={classes.start_time}>
                                Start Time : {`${new Date(fixture?.fixture_timestamp * 1000).toLocaleString("en-US",{
                                    timeZone: "Asia/Yangon",
                                    day: "2-digit", 
                                    month: "2-digit", 
                                })} - ${new Date(fixture?.fixture_timestamp * 1000).toLocaleString("en-US",{
                                    timeZone: "Asia/Yangon",
                                    hour: "2-digit", 
                                    minute: "2-digit", 
                                    hour12: true
                                })}`}
                            </time>    
                            <div className={classes.team_container}>
                                <div className={classes.teamH}>
                                    {homeTeamName}
                                    <span className={classes.teamLogoBg}>
                                        <img src={TEAM_IMAGE(fixture?.host_team_data)} />
                                    </span>
                                </div>
                                <span style={{backgroundColor: '#000626' , height: '40px', lineHeight: '40px',padding: '0 3px',fontSize: '14px', color: 'var(--accent-color)'}}>
                                    Vs
                                </span>
                                <div className={classes.teamA}>
                                    <span className={classes.teamLogoBg}>
                                        <img src={TEAM_IMAGE(fixture?.guest_team_data)} />
                                    </span>
                                    {awayTeamName}
                                </div>
                            </div>
                            {
                                (marketStatus?.fullTime && fixture?.odds?.full_time?.is_published_mm_odds) &&
                                <div className={classes.betFlex}>
                                    <div className={classes.type}>FT</div>
                                    <div style={{width: '100%'}}>
                                        <div className={classes.hdp_container}>
                                        
                                            <div className={classes.home} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_home_full_time`) ? 'var(--accent-color)':''}} onClick={() => {pressHandler(`${fixture?.fixture_id}_${homeTeamName}_full_time`,fixture,'home','body');setCorrectScoresOdds(null);}}>
                                                {langChange.football_home}
                                                {
                                                    fixture?.odds?.full_time?.odds_team === 'home'?
                                                    <span className={classes.home_odds} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_home_full_time`) ? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive.some((name:any) => name  === `${fixture?.id}_home_full_time`) ? '#FFF':(((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                        {replaceZeotoQqual(fixture?.odds?.full_time?.hdp_mm_odds)}
                                                    </span>:
                                                    ''
                                                }
                                            </div>
                                            <div className={classes.away} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_away_full_time`) ? 'var(--accent-color)':''}} onClick={() => {pressHandler(`${fixture?.fixture_id}_${awayTeamName}_full_time`,fixture,'away','body');setCorrectScoresOdds(null);}}>
                                                {langChange.football_away}
                                                {
                                                    fixture?.odds?.full_time?.odds_team === 'away'?
                                                    <span className={classes.away_odds} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_away_full_time`) ? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive.some((name:any) => name  === `${fixture?.id}_away_full_time`) ? '#FFF':(((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                        {replaceZeotoQqual(fixture?.odds?.full_time?.hdp_mm_odds)}
                                                    </span>:
                                                    ''
                                                }
                                            </div>
                                        </div>
                                        <div className={classes.ou_container}>
                                            <div className={classes.over} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_over_full_time`) ? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.fixture_id}goalUp_full_time`,fixture,'over','total');setCorrectScoresOdds(null);}}>{langChange.football_over}</div>
                                            <div className={classes.ou_odds} >
                                                <div style={{backgroundColor: (selectActive.some((name:any) => name  === `${fixture?.id}_over_full_time`)) || (selectActive.some((name:any) => name  === `${fixture?.id}_under_full_time`)) ? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.ou_mm_odds) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.ou_mm_odds) ? 'red' : ''),color: (selectActive.some((name:any) => name  === `${fixture?.id}_over_full_time`)) || (selectActive.some((name:any) => name  === `${fixture?.id}_under_full_time`)) ? '#FFF':(((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.ou_mm_odds) ? '#FFF' : '')}}>{replaceZeotoQqual(fixture?.odds?.full_time?.ou_mm_odds)}</div>
                                            </div>
                                            <div className={classes.under} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_under_full_time`) ? 'var(--accent-color)':''}} onClick={() => {pressHandler(`${fixture?.fixture_id}goalDown_full_time`,fixture, 'under','total');setCorrectScoresOdds(null);}}>{langChange.football_under}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                (marketStatus?.firstHalf && fixture?.odds?.first_half?.is_published_mm_odds) &&
                                <div className={classes.betFlex}>
                                    <div className={classes.type}>HT</div>
                                    <div style={{width: '100%'}}>
                                        <div className={classes.hdp_container}>
                                            <div className={classes.home} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_home_first_half`) ? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.fixture_id}_${homeTeamName}_first_half`,fixture,'home','body',true);setCorrectScoresOdds(null);}}>
                                                {langChange.football_home}
                                                {
                                                    fixture?.odds?.first_half?.odds_team === 'home'?
                                                    <span className={classes.home_odds} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_home_first_half`) ? (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive.some((name:any) => name  === `${fixture?.id}_home_first_half`) ? '#FFF':(((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                        {replaceZeotoQqual(fixture?.odds?.first_half?.hdp_mm_odds)}
                                                    </span>:
                                                    ''
                                                }
                                            </div>
                                            <div className={classes.away} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_away_first_half`) ? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.fixture_id}_${awayTeamName}_first_half`,fixture,'away','body',true);setCorrectScoresOdds(null);}}>
                                                {langChange.football_away}
                                                {
                                                    fixture?.odds?.first_half?.odds_team === 'away'?
                                                    <span className={classes.away_odds} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_away_first_half`) ? (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive.some((name:any) => name  === `${fixture?.id}_away_first_half`) ? '#FFF':(((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                        {replaceZeotoQqual(fixture?.odds?.first_half?.hdp_mm_odds)}
                                                    </span>:
                                                    ''
                                                }
                                            </div>
                                        </div>
                                        <div className={classes.ou_container}>
                                            <div className={classes.over} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_over_first_half`)? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.fixture_id}goalUp_first_half`,fixture,'over','total',true);setCorrectScoresOdds(null);}}>{langChange.football_over}</div>
                                            <div className={classes.ou_odds}>
                                                <div style={{backgroundColor: (selectActive.some((name:any) => name  === `${fixture?.id}_over_first_half`)) || (selectActive.some((name:any) => name  === `${fixture?.id}_under_first_half`)) ? (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.ou_mm_odds) ? 'red' : '#000626') : (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.ou_mm_odds) ? 'red' : ''),color: (selectActive.some((name:any) => name  === `${fixture?.id}_over_first_half`)) || (selectActive.some((name:any) => name  === `${fixture?.id}_under_first_half`)) ? '#FFF':(((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.ou_mm_odds) ? '#FFF' : '')}}>{replaceZeotoQqual(fixture?.odds?.first_half?.ou_mm_odds)}</div>
                                            </div>
                                            <div className={classes.under} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_under_first_half`)? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.fixture_id}goalDown_first_half`,fixture, 'under','total',true);setCorrectScoresOdds(null);}}>{langChange.football_under}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        })
                    }
                </li>
            }]}
        /> */}

        <div style={{display: 'flex', alignItems: 'center'}}>
            <h3 className={classes.leagueName}> 
                <span style={{color: 'var(--accent-color)'}}>{data[index]?.league_name}</span>
            </h3>
            <span style={{
                    display: 'block',
                    width: '18px',
                    height: '18px',
                    fontSize: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'red',
                    marginLeft: '10px',
                    color: '#FFF',
                    textAlign: 'center',
                    lineHeight: '16px'
                }}>{data[index]?.matchs.length || 0}
            </span>
        </div>
        <ul className={classes.matchs}>
            <li>
                {
                    data[index]?.matchs.length > 0 &&
                    data[index]?.matchs?.map((fixture:any) => {
                        let homeTeamName = (value === "unicode" || value === "zawgyi") ? (fixture?.host_team_data?.name_mm || fixture?.host_team_data?.name_en) : fixture?.host_team_data?.name_en;
                        let awayTeamName = (value === "unicode" || value === "zawgyi") ? (fixture?.guest_team_data?.name_mm || fixture?.guest_team_data?.name_en) : fixture?.guest_team_data?.name_en;
                        return <div key={fixture.id} className={classes.card}>
                        <time className={classes.start_time}>
                            Start Time : {`${new Date(fixture?.fixture_timestamp * 1000).toLocaleString("en-US",{
                                timeZone: "Asia/Yangon",
                                day: "2-digit", 
                                month: "2-digit", 
                            })} - ${new Date(fixture?.fixture_timestamp * 1000).toLocaleString("en-US",{
                                timeZone: "Asia/Yangon",
                                hour: "2-digit", 
                                minute: "2-digit", 
                                hour12: true
                            })}`}
                        </time>    
                        <div className={classes.team_container}>
                            <div className={classes.teamH}>
                                {homeTeamName}
                                <span className={classes.teamLogoBg}>
                                    <img src={TEAM_IMAGE(fixture?.host_team_data)} />
                                </span>
                            </div>
                            <span style={{backgroundColor: '#000626' , height: '35px', lineHeight: '35px',padding: '0 3px',fontSize: '14px', color: 'var(--accent-color)'}}>
                                Vs
                            </span>
                            <div className={classes.teamA}>
                                <span className={classes.teamLogoBg}>
                                    <img src={TEAM_IMAGE(fixture?.guest_team_data)} />
                                </span>
                                {awayTeamName}
                            </div>
                        </div>
                        {
                            (marketStatus?.fullTime && fixture?.odds?.full_time?.is_published_mm_odds) &&
                            <div className={classes.betFlex}>
                                <div className={classes.type}>FT</div>
                                <div style={{width: '100%'}}>
                                    <div className={classes.hdp_container}>
                                    
                                        <div className={classes.home} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_home_full_time`) ? 'var(--accent-color)':''}} onClick={() => {pressHandler(`${fixture?.fixture_id}_${homeTeamName}_full_time`,fixture,'home','body');setCorrectScoresOdds(null);}}>
                                            {langChange.football_home}
                                            {
                                                fixture?.odds?.full_time?.odds_team === 'home'?
                                                <span className={classes.home_odds} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_home_full_time`) ? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive.some((name:any) => name  === `${fixture?.id}_home_full_time`) ? '#FFF':(((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                    {replaceZeotoQqual(fixture?.odds?.full_time?.hdp_mm_odds)}
                                                </span>:
                                                ''
                                            }
                                        </div>
                                        <div className={classes.away} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_away_full_time`) ? 'var(--accent-color)':''}} onClick={() => {pressHandler(`${fixture?.fixture_id}_${awayTeamName}_full_time`,fixture,'away','body');setCorrectScoresOdds(null);}}>
                                            {langChange.football_away}
                                            {
                                                fixture?.odds?.full_time?.odds_team === 'away'?
                                                <span className={classes.away_odds} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_away_full_time`) ? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive.some((name:any) => name  === `${fixture?.id}_away_full_time`) ? '#FFF':(((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                    {replaceZeotoQqual(fixture?.odds?.full_time?.hdp_mm_odds)}
                                                </span>:
                                                ''
                                            }
                                        </div>
                                    </div>
                                    <div className={classes.ou_container}>
                                        <div className={classes.over} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_over_full_time`) ? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.fixture_id}goalUp_full_time`,fixture,'over','total');setCorrectScoresOdds(null);}}>{langChange.football_over}</div>
                                        <div className={classes.ou_odds} >
                                            <div style={{backgroundColor: (selectActive.some((name:any) => name  === `${fixture?.id}_over_full_time`)) || (selectActive.some((name:any) => name  === `${fixture?.id}_under_full_time`)) ? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.ou_mm_odds) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.ou_mm_odds) ? 'red' : ''),color: (selectActive.some((name:any) => name  === `${fixture?.id}_over_full_time`)) || (selectActive.some((name:any) => name  === `${fixture?.id}_under_full_time`)) ? '#FFF':(((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.ou_mm_odds) ? '#FFF' : '')}}>{replaceZeotoQqual(fixture?.odds?.full_time?.ou_mm_odds)}</div>
                                        </div>
                                        <div className={classes.under} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_under_full_time`) ? 'var(--accent-color)':''}} onClick={() => {pressHandler(`${fixture?.fixture_id}goalDown_full_time`,fixture, 'under','total');setCorrectScoresOdds(null);}}>{langChange.football_under}</div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            (marketStatus?.firstHalf && fixture?.odds?.first_half?.is_published_mm_odds) &&
                            <div className={classes.betFlex}>
                                {/* <div className={classes.type} style={{lineHeight: '55px'}}>1<sup>st</sup>H</div> */}
                                <div className={classes.type}>HT</div>
                                <div style={{width: '100%'}}>
                                    <div className={classes.hdp_container}>
                                        <div className={classes.home} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_home_first_half`) ? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.fixture_id}_${homeTeamName}_first_half`,fixture,'home','body',true);setCorrectScoresOdds(null);}}>
                                            {langChange.football_home}
                                            {
                                                fixture?.odds?.first_half?.odds_team === 'home'?
                                                <span className={classes.home_odds} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_home_first_half`) ? (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive.some((name:any) => name  === `${fixture?.id}_home_first_half`) ? '#FFF':(((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                    {replaceZeotoQqual(fixture?.odds?.first_half?.hdp_mm_odds)}
                                                </span>:
                                                ''
                                            }
                                        </div>
                                        <div className={classes.away} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_away_first_half`) ? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.fixture_id}_${awayTeamName}_first_half`,fixture,'away','body',true);setCorrectScoresOdds(null);}}>
                                            {langChange.football_away}
                                            {
                                                fixture?.odds?.first_half?.odds_team === 'away'?
                                                <span className={classes.away_odds} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_away_first_half`) ? (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive.some((name:any) => name  === `${fixture?.id}_away_first_half`) ? '#FFF':(((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                    {replaceZeotoQqual(fixture?.odds?.first_half?.hdp_mm_odds)}
                                                </span>:
                                                ''
                                            }
                                        </div>
                                    </div>
                                    <div className={classes.ou_container}>
                                        <div className={classes.over} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_over_first_half`)? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.fixture_id}goalUp_first_half`,fixture,'over','total',true);setCorrectScoresOdds(null);}}>{langChange.football_over}</div>
                                        <div className={classes.ou_odds}>
                                            <div style={{backgroundColor: (selectActive.some((name:any) => name  === `${fixture?.id}_over_first_half`)) || (selectActive.some((name:any) => name  === `${fixture?.id}_under_first_half`)) ? (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.ou_mm_odds) ? 'red' : '#000626') : (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.ou_mm_odds) ? 'red' : ''),color: (selectActive.some((name:any) => name  === `${fixture?.id}_over_first_half`)) || (selectActive.some((name:any) => name  === `${fixture?.id}_under_first_half`)) ? '#FFF':(((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.ou_mm_odds) ? '#FFF' : '')}}>{replaceZeotoQqual(fixture?.odds?.first_half?.ou_mm_odds)}</div>
                                        </div>
                                        <div className={classes.under} style={{backgroundColor: selectActive.some((name:any) => name  === `${fixture?.id}_under_first_half`)? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.fixture_id}goalDown_first_half`,fixture, 'under','total',true);setCorrectScoresOdds(null);}}>{langChange.football_under}</div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    })
                }
            </li>
        </ul>
        <div style={{height: '7px'}}></div>
    </div>
  )
}

export default ParlayMatchCard
