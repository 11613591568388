import React from 'react';
import { motion } from 'framer-motion';
import { PropsChildrenTypes } from '../types';

const PageTransition = ({children}:PropsChildrenTypes) => {
  return (
    <motion.div 
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{duration: 0.75, ease: 'easeOut'}}
    style={{height: '100%',width: '100%'}}
    >
      {children}
    </motion.div>
  )
}

export default PageTransition
