import React, { useEffect, useRef, useState } from "react";
import { Calendar, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import calendarImg from '../../assets/images/icons/calendar.png';
import { touchSong } from "../Helper";

const Range = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });
  const [showDatePicker, setShowDatePicker] = useState(false);

  useEffect(() => {
    let from = null;
    let to = null;
    if (startDate) {
      const start_date = startDate?.split("-");
      from = {
        year: Number(start_date[0]),
        month: Number(start_date[1]),
        day: Number(start_date[2]),
      };
    }
    if (endDate) {
      const end_date = endDate?.split("-");
      to = {
        year: Number(end_date[0]),
        month: Number(end_date[1]),
        day: Number(end_date[2]),
      };
    }
    setSelectedDayRange({ from, to });
  }, []);

  useEffect(() => {
    const from = selectedDayRange.from;
    const to = selectedDayRange.to;
    if (from && to) {
      setStartDate(
        `${from.year}-${
          Number(from.month) > 9 ? from.month : "0" + from.month
        }-${Number(from.day) > 9 ? from.day : "0" + from.day}`
      );
      setEndDate(
        `${to.year}-${Number(to.month) > 9 ? to.month : "0" + to.month}-${
          Number(to.day) > 9 ? to.day : "0" + to.day
        }`
      );
    }
  }, [selectedDayRange]);

  const dateRangeRef = useRef(null);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (dateRangeRef.current && !dateRangeRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <div ref={dateRangeRef} style={{width: '30px', position: 'relative'}}>
     
      <img style={{width: '100%'}} src={calendarImg} alt="Calendar" onClick={() => {
        touchSong();
        setShowDatePicker(!showDatePicker);
      }} />
      {showDatePicker && (
        <Calendar
          value={selectedDayRange}
          onChange={(e) => {
            setSelectedDayRange(e);
            if (e?.from || e?.to) {
              touchSong();
            }
            if (e?.from && e?.to) {
              setShowDatePicker(false);
            }
          }}
          calendarClassName="dateRangeExtra"
          colorPrimary="var(--accent-color)"
          colorPrimaryLight="#e2a3a368"
          shouldHighlightWeekends
          maximumDate={utils().getToday()}
          // calendarTodayClassName="bg-slate-300"
          // renderFooter={() => (
          //   <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', padding: '0 10px 10px 10px' }}>
          //     <button
          //       type="button"
          //       onClick={() => {
          //         touchSong();
          //         let date = null;
          //         const currentDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
          //         const start_date = currentDate?.split("-");
          //         date = {
          //           year: Number(start_date[0]),
          //           month: Number(start_date[1]),
          //           day: Number(start_date[2]),
          //         };
          //         setSelectedDayRange({ from: date, to: date });
          //         setShowDatePicker(false);
          //       }}
          //       style={{
          //         border: '#0fbcf9',
          //         color: '#fff',
          //         backgroundColor: 'gray',
          //         borderRadius: '0.35rem',
          //         fontSize: '16px',
          //         width: '100%',
          //         padding: '0.25rem',
          //         fontFamily: 'sans-serif'
          //       }}
          //     >
          //       Yesterday
          //     </button>
          //     <button
          //       type="button"
          //       onClick={() => {
          //         touchSong();
          //         let date = null;
          //         const currentDate = moment().format('YYYY-MM-DD');
          //         const start_date = currentDate?.split("-");
          //         date = {
          //           year: Number(start_date[0]),
          //           month: Number(start_date[1]),
          //           day: Number(start_date[2]),
          //         };
          //         setSelectedDayRange({ from: date, to: date });
          //         setShowDatePicker(false);
          //       }}
          //       style={{
          //         border: '#0fbcf9',
          //         color: '#fff',
          //         backgroundColor: 'gray',
          //         borderRadius: '0.35rem',
          //         fontSize: '16px',
          //         width: '100%',
          //         padding: '0.25rem',
          //         fontFamily: 'sans-serif'
          //       }}
          //     >
          //       Today
          //     </button>
          //   </div>
          // )}
        />
      )}
    </div>
  );
};

export default Range;
