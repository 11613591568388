import React, { useEffect } from 'react';
import {  BrowserRouter as Router } from 'react-router-dom';
import Routers from './routes';
import { useSelector } from 'react-redux';
import { langChange } from './lang';

function App() {
  const {value} = useSelector((state:any) => state.lang);  
  
  useEffect(()=>{
    localStorage.setItem("lang",value);
    langChange.setLanguage(value);
  },[value]);

  return (
    <>
      <Router basename="">
        <Routers />
      </Router>
      
    </>
  );
}

export default App;
