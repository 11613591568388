import { useQuery } from "@apollo/client";
import { GET_WALLET_HISTORY_DATA, GET_WALLET_HISTORY_DETAIL_HISTORY } from "./graphql/queries";
import { FetchGraphQL } from "./graphql/queryService";

export const getTransactionHistory = ({ payload , pollInterval = 0 ,isLazy=false}) => {
    let result = FetchGraphQL({
      query: GET_WALLET_HISTORY_DATA,
      variables: payload,
      pollInterval: pollInterval,
      isLazy: isLazy
    });
    return result;
};

export const getTransactionHistoryDetail = ({ payload , pollInterval = 0 ,isLazy=false}) => {
  let result = FetchGraphQL({
    query: GET_WALLET_HISTORY_DETAIL_HISTORY,
    variables: payload,
    pollInterval: pollInterval,
    isLazy: isLazy
  });
  return result;
};
