import React from 'react'
import { NotFoundTypes } from '../types'

const NotFound = ({text}: NotFoundTypes) => {
  return (
    <div style={{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '14px'
    }}>
        <p>{text}</p>
    </div>
  )
}

export default NotFound
