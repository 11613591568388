import React, { useEffect } from 'react'
import AlertMessage from '../AlertMessage/AlertMessage'
import UserInfo from '../UserInfo/UserInfo'
import FootballTypes from '../Football/FootballTypes/FootballTypes'
import Banner from '../Banner/Banner'
import { useDispatch, useSelector } from 'react-redux'
import { FetchTaxPercent } from '../../services/bettingService'
import { createPortal } from 'react-dom'
import Loader from '../../utils/Loader/Loader'
import { encodeAuth } from '../../utils/Helper'
import { publicGetMethodService } from '../../services'
import { TOTAL_MATCHES_API } from '../../services/apis'
import { callStatusHandler } from '../../store/slices/callMatchsApiSlice'
const Cookies = require('js-cookie');

const Home = () => {
  const dispatch = useDispatch();
    const {
        loading: taxLoading,
        data: taxPercentData,
        error: taxPercentError,
        refetch: reloadData
    } = FetchTaxPercent({
        payload: null,
        pollInterval: 0,
    });

    useEffect(()=>{
        const encoded = encodeAuth(taxPercentData);
        localStorage.setItem("tax", encoded);
    },[taxPercentData]);

    const fetchTotalMatches = async () => {
      const res = await publicGetMethodService(TOTAL_MATCHES_API);
      if(res){
          if(res?.status === "success"){
            Cookies.set('total_matches', res?.data);
          };
      };
    };
  
    useEffect(()=>{
      fetchTotalMatches();
      dispatch(callStatusHandler(false));
    },[]);

  return (
    <>
      <div>
        {/* <AlertMessage mb='10px' /> */}
        <Banner />
        {/* <UserInfo mb='10px' /> */}
        <FootballTypes />
      </div>
      {createPortal(
        taxLoading && <Loader />,
        document.getElementById("loading") as HTMLElement
      )}
    </>
  )
}

export default Home
