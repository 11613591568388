import React from 'react';
import { decodeAuth } from '../utils/Helper';
import { Navigate } from 'react-router-dom';
const Cookies = require('js-cookie');

const IsSignIn = ({children}:any) => {
    const getAuth = Cookies.get('=d;£6792)Dr!');
    if(getAuth){
      return <Navigate to='/' />
    }else{
      return children
    };
}

export default IsSignIn
