import React, { useState } from "react";
import classes from "./Profile.module.css";
import {
  MdHistory,
  MdKeyboardArrowRight,
  MdLockReset,
  MdPhoneIphone,
} from "react-icons/md";
import { FaEye, FaTelegram, FaViber } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { LogoutHandle } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { Button, message, Modal } from "antd";
import { PiSignOutBold } from "react-icons/pi";
import { CgNotes } from "react-icons/cg";
import { IoLanguage } from "react-icons/io5";
import { postMethodService } from "../../services";
import { LOGOUT_API } from "../../services/apis";
import { langHandler } from '../../store/slices/langSlice';
import { langChange } from "../../lang";
import { createPortal } from "react-dom";
import Loader from "../../utils/Loader/Loader";
const mmFlag = require('../../assets/images/icons/mm.png');
const engFlag = require('../../assets/images/icons/eng.png');

const Profile = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [langModal, setLangModal] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const {value} = useSelector((state:any) => state.lang);
  const [langType,setLangType] = useState<any>(value);
  const { userInfo } = useSelector((state: any) => state.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const alertMessage = (status: any, msg: any) => {
    messageApi.open({
      type: status,
      content: msg,
    });
  };

  const handleSignOut = async () => {
    setIsLoading(true);
    let res = await postMethodService(LOGOUT_API);
    if (res) {
      setIsLoading(false);
      if (res === "Unauthenticated") {
        return LogoutHandle(navigate);
      }
      if (res.status === "success" || res.status || res.success) {
        alertMessage("success", "အောင်မြင်ပါသည်။");
        setTimeout(() => LogoutHandle(navigate), 600);
      } else {
        alertMessage("error", res);
      }
    }
  };

  const langChangeHandler = () => {
    setLangModal(false);
    dispatch(langHandler(langType));
    langChange.setLanguage(langType);
  };
  return (
    <>
      {contextHolder}
      <div>
        <div className={classes.topBackground}>
          <div className={classes.bgImg}></div>
          <div className={classes.profileImageContainer}></div>
        </div>
        <h4 className={classes.usercode}>{userInfo?.usercode || "-"}</h4>
        <ul className={classes.profileListContainer}>
          <li>
            <h4 className={classes.listTitle}>{langChange.setting}</h4>
            <div className={classes.underline}></div>
          </li>
          <li onClick={() => setLangModal(true)}>
              <div style={{padding: value === 'eng'?'13.5px 10px': '8px 10px'}} className={classes.listDesc}>
                  <div>
                      <IoLanguage size={25} color='#fff' />
                      <span>{langChange.language}</span>
                  </div>
                  <MdKeyboardArrowRight size={25} color='var(--accent-color)' />
              </div>
          </li>
          <li>
            <Link style={{ textDecoration: "none"}} to="/change-password">
              <div style={{padding: value === 'eng'?'13.5px 10px': '8px 10px'}} className={classes.listDesc}>
                <div>
                  <MdLockReset size={25} color="#fff" />
                  <span>{langChange.change_password}</span>
                </div>
                <MdKeyboardArrowRight size={25} color="var(--accent-color)" />
              </div>
            </Link>
          </li>
          <li>
            <Link style={{ textDecoration: "none" }} to="/wallet-transaction">
              <div style={{padding: value === 'eng'?'13.5px 10px': '8px 10px'}} className={classes.listDesc}>
                <div>
                  <MdHistory size={25} color="#fff" />
                  <span>{langChange.wallet_transaction}</span>
                </div>
                <MdKeyboardArrowRight size={25} color="var(--accent-color)" />
              </div>
            </Link>
          </li>
          <li>
            <Link style={{ textDecoration: "none" }} to="/rule-regulations">
              <div style={{padding: value === 'eng'?'13.5px 10px': '8px 10px'}} className={classes.listDesc}>
                <div>
                  <CgNotes size={25} color="#fff" />
                  <span>{langChange.rule_regulations}</span>
                </div>
                <MdKeyboardArrowRight size={25} color="var(--accent-color)" />
              </div>
            </Link>
          </li>
          <li>
            <div
              className={classes.listDesc}
              onClick={handleSignOut}
              style={{padding: value === 'eng'?'13.5px 10px': '8px 10px'}}
            >
              <div>
                <PiSignOutBold size={25} color="#fff" />
                <span>{langChange.signout}</span>
              </div>
              <MdKeyboardArrowRight size={25} color="var(--accent-color)" />
            </div>
          </li>
        </ul>
      </div>
      <Modal
        centered
        open={langModal}
        closable={false}
        cancelText="Close"
        footer={[
          <Button className={classes.ok} key="ok" onClick={langChangeHandler}>
            {langChange.ok}
          </Button>,
        ]}
        className="profile-modal"
        // width={400}
        width="auto"
      >
         <p className={classes.modalTitle}>{langChange.change_lang}</p>
         <ul className={classes.types}>
            <li>
                <div style={{backgroundColor: langType === "unicode"? '#d18700b0': '#ffffff24'}} className={classes.type} onClick={() => setLangType("unicode")}>
                     <img src={mmFlag} />
                     <p>Unicode</p>
                </div>
            </li>
            <li>
                <div style={{backgroundColor: langType === "zawgyi"? '#d18700b0': '#ffffff24'}} className={classes.type} onClick={() => setLangType("zawgyi")}>
                     <img src={mmFlag} />
                     <p>Zawgyi</p>
                </div>
            </li>
            <li>
                <div style={{backgroundColor: langType === "eng"? '#d18700b0': '#ffffff24'}} className={classes.type} onClick={() => setLangType("eng")}>
                     <img src={engFlag} />
                     <p>English</p>
                </div>
            </li>
         </ul>
      </Modal>
      {createPortal(
        isLoading && <Loader />,
        document.getElementById("loading") as HTMLElement
      )}
    </>
  );
};

export default Profile;
