import React, { useState } from 'react';
import classes from './Dates.module.css'
import { useNavigate } from 'react-router-dom';
import { WeekDates } from '../../utils/Helper';
import moment from 'moment';
import { IoVideocamSharp } from 'react-icons/io5';
import { FaStar } from 'react-icons/fa';
import { langChange } from '../../lang';
import { useSelector } from 'react-redux';

const Dates = ({matchsType, setMatchsType, filterDate, setFilterDate,setIsLoading}:any) => {
  const dates = WeekDates();
  const navigate = useNavigate();
  const {value} = useSelector((state:any) => state.lang);

  const _dateIsToday = (date:any) => {
    let currendDay = moment().format('ddd');
    let eachDay = moment(date).format('ddd');
    if(currendDay == eachDay){
        return "Today";
    }else{
        return moment(date).format('ddd');
    };
  };

  const dateHandler = (date:any) => {
    setFilterDate(date);
  };

  const matchTypesHandler = (type:string) => {
    setMatchsType(type);
    setIsLoading(true);
  }
  return (
    <div>
        <ul className={classes.typeTabs}>
            <li className={matchsType === 'inplay' ? classes.activeType : ''} onClick={() => matchTypesHandler('inplay')}>
                <div style={{padding: value === "eng" ? '10px': '5px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {langChange.live}
                    <IoVideocamSharp style={{marginLeft: '5px'}} size={16} />
                </div> 
            </li>
            <li style={{padding: value === "eng" ? '10px': '5px'}} className={matchsType === 'completed' ? classes.activeType : ''} onClick={() => matchTypesHandler('completed')}>
                {langChange.full_time}
            </li>
            <li style={{padding: value === "eng" ? '10px': '5px'}} className={matchsType === 'first-half-complete' ? classes.activeType : ''} onClick={() => matchTypesHandler('first-half-complete')}>
                {langChange.first_half}
            </li>
            <li style={{padding: value === "eng" ? '10px': '5px'}} className={matchsType === 'first-half-reject' ? classes.activeType : ''} onClick={() => matchTypesHandler('first-half-reject')}>
                {langChange.rejected_first_half}
            </li>
            <li style={{padding: value === "eng" ? '10px': '5px'}} className={matchsType === 'cancel' ? classes.activeType : ''} onClick={() => matchTypesHandler('cancel')}>
                {langChange.rejected_full_time}
            </li>
            <li style={{padding: value === "eng" ? '10px': '5px'}} onClick={() => navigate('/live-goals/favourite')}>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {langChange.favourites}
                    <FaStar color='var(--accent-color)' style={{marginLeft: '5px'}} size={16} />
                </div> 
            </li>
        </ul>
        {
            matchsType !== 'inplay'&&
            <ul className={classes.tabs}>
                {
                    dates?.length > 0 &&
                    dates?.map((el:any,i:number) => 
                    <li key={i} className={_dateIsToday(filterDate) === _dateIsToday(el) ? classes.active : ''} onClick={() => dateHandler(el)}>
                        <div style={{marginBottom: '2px', textTransform: 'uppercase'}}>{_dateIsToday(el)}</div>
                        <div>{moment(el).format('DD/MM')}</div>
                    </li>
                    )
                }
            </ul>
         }
    </div>
  )
}

export default Dates
