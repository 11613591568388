import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout';
import Maintenance from '../../components/Maintenance/Maintenance';

const MaintenancePage = () => {

    // useEffect(() => {

    //       const loadScript = (src:any) => {
    //         const script = document.createElement('script');
    //         script.src = src;
    //         script.async = true;
    //         document.body.appendChild(script);
    //       };
    //       loadScript('/game/football-game/js/vendor/modernizr-2.6.2.min.js');
    //       loadScript('/game/football-game/js/vendor/pathAnimator.js');
    //       loadScript('/game/football-game/js/main.js');
    //   }, []);

  return (
    <Layout type="" title='' filter="" showHeader={false} showFooter={false} home={false} showDate={false} download={false} showWallet={false} search={false}>
       {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> */}
        <Maintenance />
    </Layout>
  )
}

export default MaintenancePage
