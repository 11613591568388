import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  userInfo: {},
}

export const userInfoSlice:any = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfoHandler: (state, action: any) => {
        state.userInfo = action.payload
    },
  },
})

export const { setUserInfoHandler } = userInfoSlice.actions

export default userInfoSlice.reducer