import React from 'react'
import Layout from '../../layout/Layout'
import BetHistory from '../../components/Football/BetHistory/BetHistory'
import { langChange } from '../../lang'

const BetHistoryPage = () => {
  return (
    <Layout type='' title={langChange.slips} filter="" showHeader={true} showFooter={false} home={false} showDate={true} download={false} showWallet={false} search={false}>
        <BetHistory />
    </Layout>
  )
}

export default BetHistoryPage
