import React from 'react';
import classes from './RuleAndRegulations.module.css';
import { MdStar } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { LogoutHandle } from '../../utils/Helper';
import { Button } from 'antd';
import { postMethodService } from '../../services';
import { LOGOUT_API } from '../../services/apis';
import { langChange } from '../../lang';

const RuleAndRegulations = () => {
  const navigate = useNavigate();
  const backKeyHandler = async () => {
    let res = await postMethodService(LOGOUT_API);
    if (res) {
      if (res === "Unauthenticated") {
        return LogoutHandle(navigate);
      }
      if (res.status === "success" || res.status || res.success) {
        setTimeout(() => LogoutHandle(navigate));
      } else {
        return LogoutHandle(navigate);
      }
    }
  };

  return (
    <>
      <div className={classes.rulesContainer}>
          <h1>စည်းကမ်း နှင့် သတ်မှတ်ချက်များ</h1>
          <div className={classes.content}>
            <ul className={classes.rulesList}>
              <li>
                  <h3>ဘော်ဒီ / ဂိုးပေါင်း</h3>
              </li>
              <li>
                  <div style={{marginRight: '7px'}}>
                    <MdStar size="17px" style={{marginTop: '5px'}} color='var(--accent-color)'/>
                  </div>
                  <p>
                    ဘော်ဒီ / ဂိုးပေါင်း ကစားရာတွင် ပွဲကြီး ( ပွဲပြီး ) <span style={{color: 'var(--accent-color)',fontWeight: 'bolder'}}>5%</span> &nbsp; ၊ &nbsp; ပွဲသေး ( ပွဲပြီး ) <span style={{color: 'var(--accent-color)',fontWeight: 'bolder'}}>8%</span> &nbsp;
                    ၊ &nbsp; စုံ / မ ( ပွဲပြီး ) <span style={{color: 'var(--accent-color)',fontWeight: 'bolder'}}>0.5 - 2</span> &nbsp; ၊ &nbsp; 1 x 2 ( ပွဲပြီး ) <span style={{color: 'var(--accent-color)',fontWeight: 'bolder'}}>0.5 ,1 & 2</span> &nbsp; ၊ &nbsp; Correct Scores ( ပွဲပြီး ) <span style={{color: 'var(--accent-color)',fontWeight: 'bolder'}}>10</span> &nbsp;
                    ပွဲကြီးများကို ကြယ် အမှတ်အသားဖြင့် ဖော်ပြပေးထားပါသည်။
                  </p>
              </li>
              <li>
                  <div style={{marginRight: '7px'}}>
                    <MdStar size="17px" style={{marginTop: '5px'}} color='var(--accent-color)'/>
                  </div>
                  <p>
                      ဘော်ဒီ / ဂိုးပေါင်း ကစားရာတွင် လောင်းထားသောပွဲစဉ်မှာ ပွဲစဉ်ပျက်သွားပါက လောင်းကြေးငွေပြန်လည်ရရှိမည်ဖြစ်သည်။
                  </p>
              </li>
              <li>
                  <div style={{marginRight: '7px'}}>
                    <MdStar size="17px" style={{marginTop: '5px'}} color='var(--accent-color)'/>
                  </div>
                  <p>
                    ဂိုးရလဒ်များကို Betting Company ( sbobet, bet365, ibet789 ) များဖြင့်အတည်ပြုကာ အလျော်အစားပြုလုပ်ပါမည်။
                  </p>
              </li>
              <li>
                  <h3>မောင်း</h3>
              </li>
              <li>
                  <div style={{marginRight: '7px'}}>
                    <MdStar size="17px" style={{marginTop: '5px'}} color='var(--accent-color)'/>
                  </div>
                  <p>
                      မောင်းစာမျက်နှာတွင် မောင်းများကို အနည်းဆုံး (၂) ပွဲမှ (၁၁) ပွဲအထိ ကစားနိုင်ပြီး ရွေးချယ်ထားသောပွဲများမှ ပွဲပျက်ရှိခဲ့လျှင် ထိုပွဲပျက်အားကျော်၍ ကျန်ပွဲစဉ်များအား အပြောင်းအလဲမရှိ အလျော်အစား ဆက်လုပ်ပါမည်။
                  </p>
              </li>
              <li>
                  <div style={{marginRight: '7px'}}>
                    <MdStar size="17px" style={{marginTop: '5px'}} color='var(--accent-color)'/>
                  </div>
                  <p>
                    မောင်း ကစားရာတွင် ( ၂ ) မောင်း <span style={{color: 'var(--accent-color)',fontWeight: 'bolder'}}>15%</span> &nbsp; ၊ &nbsp;
                    ( ၃ - ၈ ) မောင်း <span style={{color: 'var(--accent-color)',fontWeight: 'bolder'}}>20%</span> &nbsp; ၊ &nbsp;
                    ( ၉ - ၁၁ ) မောင်း <span style={{color: 'var(--accent-color)',fontWeight: 'bolder'}}>20%</span> &nbsp; ၊ &nbsp;
                  </p>
              </li>
              <li>
                  <h3>ပယ်ဖျက်ခြင်း</h3>
              </li>
              <li>
                  <div style={{marginRight: '7px'}}>
                    <MdStar size="17px" style={{marginTop: '5px'}} color='var(--accent-color)'/>
                  </div>
                  <p>
                    နည်းပညာ ချွတ်ယွင်း၍ အခြားသော Betting Companyများနှင့် ဈေးနှုန်း ပေါက်ကြေး အလွန်ကွဲပြားနေပါက ၎င်းပေါက်ကြေးဖြင့် ကစားထားသော မောင်းနှင့်ဘော်ဒီများအား Cancel ပြုလုပ်၍ အရင်းငွေသာပြန်အမ်းပါမည်။
                  </p>
              </li>
              <li>
                  <div style={{marginRight: '7px'}}>
                    <MdStar size="17px" style={{marginTop: '5px'}} color='var(--accent-color)'/>
                  </div>
                  <p>
                    သာမာန် ကစားနည်းအတိုင်း မဟုတ်ပဲ ကြေးအသေ ၊ သီးသန့် ကြားထိုး ကစားပါက အဆိုပါ User ၏ကြားထိုး အကြောင်းအား Reject ပြုလုပ်၍ လောင်းကြေးသာငွေပြန်အမ်းပါမည် 
                  </p>
              </li>
              
            </ul>
          </div>
          <div className={classes.rulesFooter}>
              <p>
                  အထက်ပါစည်းကမ်းနှင့်သတ်မှတ်ချက် များကို သေချာဖတ်ရှုပြီး သဘောတူပါသည်။
              </p>
              <div style={{
                  display: 'flex',
                  marginTop: '10px'
              }}>
                  <Button onClick={backKeyHandler} type="primary" style={{width: '100%', height: 45, backgroundColor: 'gray',boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px", borderRadius: '12px', marginRight: '10px'}}>
                      {langChange.cancel}
                  </Button>
                  <Button onClick={() => navigate('/')} type="primary" htmlType="submit" style={{width: '100%', height: 45, backgroundColor: 'var(--accent-color)', borderRadius: '12px', marginLeft: '10px',boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"}}>
                      {langChange.confirm}
                  </Button>
              </div>
          </div>
      </div>
    </>
  )
}

export default RuleAndRegulations
