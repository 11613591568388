import React from 'react'
import Layout from '../../layout/Layout'
import Body from '../../components/Football/Body/Body'
import { langChange } from '../../lang'

const OneXTwoPage = () => {
  return (
    <Layout type='football' title="1 X 2" filter="one-x-two" showHeader={false} showFooter={false} home={false} showDate={false} download={false} showWallet={false} search={false}>
       <Body />
    </Layout>
  )
}

export default OneXTwoPage
