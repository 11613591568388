import React from 'react'
import Layout from '../../layout/Layout'
import LiveGoals from '../../components/LiveGoals/LiveGoals'
import { langChange } from '../../lang'

const LiveGoalsPage = () => {
  return (
    <Layout type='' title={langChange.liveGoals} filter="" showHeader={true} showFooter={false} home={false} showDate={false} download={false} showWallet={false} search={true}>
        <LiveGoals />
    </Layout>
  )
}

export default LiveGoalsPage
