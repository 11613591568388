import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment';
import { getTodayDate } from '../../utils/Helper';

const currentDate = moment().format("YYYY-MM-DD");
const today = getTodayDate(currentDate, currentDate);

const initialState = {
    date_range: {
        start_date: today.start_date,
        end_date: today.end_date
    },
};
export const dateRangeSlice = createSlice({
    name: 'date_range',
    initialState,
    reducers: {
        setDateRange: (state, action) => {
            state.date_range = action.payload
        },
    },
});

export const { setDateRange } = dateRangeSlice.actions
export default dateRangeSlice.reducer