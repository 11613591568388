import React from 'react';
import { decodeAuth } from '../utils/Helper';
import { Navigate } from 'react-router-dom';
const Cookies = require('js-cookie');

const Checked = ({children,typeName}:any) => {
  const getAuth = Cookies.get('=d;£6792)Dr!');
  if(typeName === "change-password"){
    if(getAuth){
        return children;
    }else{
      return <Navigate to='/sign-in' />
    };
  }else{
    if(getAuth){
      let decode = decodeAuth(getAuth);
      if(decode?.is_password_changed){
          return children;
      }else{
        return <Navigate to='/change-password' />
      };
  
    }else{
      return <Navigate to='/sign-in' />
    };
  };
}

export default Checked
