import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  sortByType: "time",
}

export const sortBySlice:any = createSlice({
  name: 'sortBy',
  initialState,
  reducers: {
    sortByTypeHandler: (state, action: any) => {
        state.sortByType = action.payload
    },
  },
})

export const { sortByTypeHandler } = sortBySlice.actions

export default sortBySlice.reducer