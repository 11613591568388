import React, { useCallback, useEffect, useRef, useState } from 'react';
import classes from './Matchs.module.css';
import { MdStar } from "react-icons/md";
import { replaceZeotoQqual, touchSong } from '../../../utils/Helper';
import { useSelector } from 'react-redux';
import { Collapse, message } from 'antd';
import DrawerSlide from '../../../utils/DrawerSlide';
import { TEAM_IMAGE } from '../../../services/apis';
import { langChange } from '../../../lang';
import { useWindowResize } from '../../../hook/useWindowResize';
import ParlayMatchCard from './ParlayMatchCard';
const { VariableSizeList } = require('react-window');
const noImage = require('../../../assets/images/icons/no-image.png');

const MatchsParlay = ({data,selectActive,pressHandler,changeFtOdds,changeFhOdds}:any) => {
  const {marketStatus} = useSelector((state:any) => state.marketOdds);
  const [openCorrectScores,setOpenCorrectScores] = useState(false);
  const [correctScoresFixture,setCorrectScoresFixture] = useState<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [correctScoresOdds,setCorrectScoresOdds] = useState<any>(null);
  const {value} = useSelector((state:any) => state.lang);
  const instanceRef:any = useRef();
  
  const alertMessage = (status:any, msg:any) => {
    messageApi.open({
      key: 'betMessage',
      type: status,
      content: msg,
    });
  };

  const showCorrectScores = (fixture:any) => {
    if(fixture?.correct_scores?.full_time && fixture?.correct_scores?.full_time?.length === 0){
        return alertMessage('error','Correct Scores ပွဲ မရှိပါ');
    };
    setOpenCorrectScores(true);
    setCorrectScoresFixture(fixture);
    touchSong();
  };
  const hideCorrectScores = () => {
    setOpenCorrectScores(false);
    touchSong();
    setCorrectScoresFixture(null);
  };


  useEffect(() => { !!data?.length && instanceRef?.current?.resetAfterIndex?.(0); }, [data]);

  const sizeMap:any = useRef({});
  const setSize = useCallback((index:any, size:any) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    instanceRef.current.resetAfterIndex(index);
  }, []);
  const getSize = (index:any) => sizeMap.current[index] || 210;
  const [windowWidth] = useWindowResize();

  return (
    <>
        {contextHolder}
        <VariableSizeList
            ref={instanceRef}
            height={window.innerHeight}
            width="100%"
            itemCount={data?.length || 0}
            itemSize={getSize}
            itemData={data}
        >
            {
                ({ data, index, style }:any) => (
                    <div style={style}>
                        <ParlayMatchCard
                            index={index}
                            data={data}
                            setSize={setSize}
                            windowWidth={windowWidth}
                            
                            pressHandler={pressHandler}
                            selectActive={selectActive}
                            changeFtOdds={changeFtOdds}
                            changeFhOdds={changeFhOdds}
                        />
                    </div>
                )
            }
        </VariableSizeList>
        {
            <DrawerSlide
                title={langChange.correct_scores}
                onClose={hideCorrectScores}
                open={openCorrectScores}
                placement="bottom"
                height={500}
                showCloseBtn={false}
            >
                <div className={classes.team_container}>
                    <div className={classes.teamH}>
                        {
                            (value === "unicode" || value === "zawgyi") ? (correctScoresFixture?.host_team_data?.name_mm  || correctScoresFixture?.host_team_data?.name_en) : correctScoresFixture?.host_team_data?.name_en
                        }
                        <span className={classes.teamLogoBg}>
                            <img src={TEAM_IMAGE(correctScoresFixture?.host_team_data)} />
                        </span>
                    </div>
                    <span style={{backgroundColor: '#000626' , height: '35px', lineHeight: '35px',padding: '0 3px',fontSize: '13px', color: 'var(--accent-color)'}}>
                        Vs
                    </span>
                    <div className={classes.teamA}>
                        <span className={classes.teamLogoBg}>
                            <img src={TEAM_IMAGE(correctScoresFixture?.guest_team_data)} />
                        </span>
                        { 
                            (value === "unicode" || value === "zawgyi") ? (correctScoresFixture?.guest_team_data?.name_mm || correctScoresFixture?.guest_team_data?.name_en) : correctScoresFixture?.guest_team_data?.name_en
                        }
                    </div>
                </div>
                <div className={classes.correctScoresContainer}>
                    {
                        correctScoresFixture?.correct_scores?.full_time?.filter((scores:any) => scores.header != 2).map((correctScore:any) => 
                            <div className={classes.correctScoresButtonContainer} key={correctScore?.id} onClick={() => {pressHandler(`${correctScoresFixture?.fixture_id}_${correctScore?.name}_full_time`,correctScoresFixture,correctScore?.name,'correct_score',false, correctScore?.odds);setCorrectScoresOdds({id: correctScoresFixture?.id,value: `${correctScore?.odds} ( ${correctScore?.name} )`});hideCorrectScores();}}>
                                <div className={classes.correctScoresOddsContainer}>
                                    <div className={classes.correctScoresOdds} style={{backgroundColor: selectActive === `${correctScoresFixture?.fixture_id}_${correctScore?.name}_full_time`?'var(--accent-color)':'#9596b6'}}>
                                        {correctScore?.odds}
                                    </div>
                                    <div className={classes.correctScoresResult}>{correctScore?.name}</div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </DrawerSlide>
        }
    </>
  )
}

export default MatchsParlay