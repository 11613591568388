import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { decodeAuth } from '../../utils/Helper';
import Cookies from 'js-cookie';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

// Set up the middleware to include the Authorization header
const authLink = setContext((_, { headers }) => {
  // Retrieve the token from wherever you have it stored (localStorage, state, etc.)
  const getAuth = Cookies.get('=d;£6792)Dr!');
  let userAuth = getAuth && decodeAuth(getAuth);
  let token = `${userAuth?.token}`;
  // Return the headers with the Authorization token
  return {
    headers: {
      ...headers,
      authorization: token ? token : "", // Include the token in the Authorization header
    }
  }
});

const link = authLink.concat(httpLink);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

export default client;
